import { Select } from "antd";
import { COLORS } from "../../Constants/styles.constants";
import * as styles from "./AboutBusiness.styles";
import { Button, Input } from "../../Components";
import { useEffect, useRef, useState } from "react";
import { http } from "../../Utilities";
import {
  Brand,
  BrandResponse,
  FormErrors,
  FormValues,
  MetaOnboardingResponse,
  MetaOnboardingResult,
} from "./AboutBusiness.types";
import { Formik, FormikProps, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ErrorMessage } from "../../Utilities/Styles/CommonStyledComponents";
import { useMutation } from "@tanstack/react-query";
import { ServerResponseData } from "../../Utilities/Types";
import {
  DEV_SPECIFIED_ERR,
  ERROR_MESSAGES,
  mapServerErrorsToLocal,
  resetServerErrors,
} from "../../Utilities/Http/http.utilities";
import { useNavigate } from "react-router-dom";

const AboutBusiness = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
    >
      <div style={{ width: "370px", marginBottom: "140px" }}>
        <h3
          style={{
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "30px",
            textAlign: "center",
            marginBottom: "4px",
          }}
        >
          Let’s get to know your brand
        </h3>
        <p
          style={{
            textAlign: "center",
            paddingBottom: "28px",
            marginBottom: "28px",
            borderBottom: `1px solid ${COLORS.GREY200}`,
            color: COLORS.GREY500,
          }}
        >
          Tell us a bit about your brand and business
        </p>
        <AboutBusinessForm />
      </div>
    </div>
  );
};

export default AboutBusiness;

const AboutBusinessForm = () => {
  const navigation = useNavigate();
  const [options, setOptions] = useState<null | MetaOnboardingResult>(null);
  const [brandData, setBrandData] = useState<null | Brand>(null);

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Business name must be at least 3 characters long")
      .required("Business name is required")
      .max(50, "Business name cannot exceed 50 characters"),
    business_type: Yup.string().optional(),
    company_size: Yup.string().optional(),
    collaboration_experience: Yup.string().optional(),
    website: Yup.string().url().optional(),
  });

  const initialValues: FormValues = {
    name: brandData?.name || "",
    business_type: brandData?.business_type || undefined,
    company_size: brandData?.company_size || undefined,
    collaboration_experience: brandData?.collaboration_experience || undefined,
    website: brandData?.website || "",
  };

  const initialErrors: FormErrors = {
    name: "",
    business_type: "",
    company_size: "",
    collaboration_experience: "",
    website: "",
    general: "",
  };

  const [serverErrors, setServerErrors] = useState<FormErrors>(initialErrors);

  async function fetchBrandOnboardingData() {
    const endpoint = "/meta/brand-onboarding";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<MetaOnboardingResponse>(
        endpoint,
        params,
        options
      );
      setOptions(response.result);
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  async function fetchBrandData() {
    const endpoint = "/brand";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<BrandResponse>(endpoint, params, options);
      setBrandData(response.result.brand);
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  const handleUpdatePartner = async (
    values: FormValues
  ): Promise<ServerResponseData<any, FormValues>> => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== "")
    );

    const endpoint = "/brand";
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      // Assuming the HTTP client returns data in the expected format
      const response = await http.put<ServerResponseData<any, FormValues>>(
        endpoint,
        { updates: filteredValues },
        options
      );
      return response;
    } catch (error) {
      console.error("Error updating partner details:", error);
      throw error; // Rethrow to trigger the `onError` handler
    }
  };

  const onUpdateSuccess = (res: ServerResponseData<any, FormValues>) => {
    if (res?.error?.status_code) {
      const errors = mapServerErrorsToLocal<any, FormErrors, FormValues>(res);
      setServerErrors(errors);
      throw new Error(DEV_SPECIFIED_ERR);
    }
    if (formikRef.current && formikRef.current.values) {
      navigation("/onboarding/contact-details/");
    }
  };

  const onUpdateError = ({
    data: res,
  }: {
    data: ServerResponseData<any, FormValues>;
  }) => {
    if (
      Object.values(serverErrors).some((error) => error) &&
      res?.message !== DEV_SPECIFIED_ERR
    ) {
      setServerErrors((prev) => ({
        ...prev,
        general: ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG,
      }));
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleUpdatePartner,
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  });

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    try {
      // Call the mutation function
      await mutateAsync(values);
      formikHelpers.setSubmitting(false);
    } catch (error) {
      console.error("Error during form submission:", error);
      formikHelpers.setSubmitting(false); // Stop the form submission state
    }
  };

  useEffect(() => {
    fetchBrandOnboardingData();
    fetchBrandData();
  }, []);

  if (options && brandData) {
    return (
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          handleBlur,
          touched,
          isValid,
        }) => {
          return (
            <div>
              <styles.FormLabel>Brand Name*</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter your Brand name or Business name"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["name"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("name", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("name")(e);
                  }}
                  value={values.name}
                />
                {(touched.name && errors.name) || serverErrors.name ? (
                  <ErrorMessage>
                    {errors.name || serverErrors.name}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>What describes you best?</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Select
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select"
                  onChange={(val) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["business_type"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("business_type", val);
                  }}
                  value={values.business_type}
                  options={options.business_types.map((curr) => {
                    return {
                      label: curr,
                      value: curr,
                    };
                  })}
                />
                {(touched.business_type && errors.business_type) ||
                serverErrors.business_type ? (
                  <ErrorMessage>
                    {errors.business_type || serverErrors.business_type}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Your company size</styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <Select
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select"
                  value={values.company_size}
                  onChange={(val) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["company_size"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("company_size", val);
                  }}
                  options={options.company_sizes.map((curr) => {
                    return {
                      label: curr.slice(0, -1) + " Employees" + curr.slice(-1),
                      value: curr,
                    };
                  })}
                />
                {(touched.company_size && errors.company_size) ||
                serverErrors.company_size ? (
                  <ErrorMessage>
                    {errors.company_size || serverErrors.company_size}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>
                Your experience in influencer marketing
              </styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <Select
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select"
                  value={values.collaboration_experience}
                  onChange={(val) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["collaboration_experience"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("collaboration_experience", val);
                  }}
                  options={options.collaboration_experiences.map((curr) => {
                    return {
                      label: `${curr} Campaigns`,
                      value: curr,
                    };
                  })}
                />
                {(touched.collaboration_experience &&
                  errors.collaboration_experience) ||
                serverErrors.collaboration_experience ? (
                  <ErrorMessage>
                    {errors.collaboration_experience ||
                      serverErrors.collaboration_experience}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Website or Public URL</styles.FormLabel>
              <div>
                <Input
                  placeholder="Enter your company website or linkedin page url"
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["website"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("website", e.target.value);
                  }}
                  onBlur={handleBlur("website")}
                  value={values.website}
                />
                {(touched.website && errors.website) || serverErrors.website ? (
                  <ErrorMessage>
                    {errors.website || serverErrors.website}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.Footer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    style={{ minWidth: "180px" }}
                    text="Next"
                    type="primary"
                    onClick={handleSubmit}
                    disabled={!isValid || !values.name}
                    loading={isPending}
                  />
                  {serverErrors.general && (
                    <ErrorMessage>{serverErrors.general}</ErrorMessage>
                  )}
                </div>
              </styles.Footer>
            </div>
          );
        }}
      </Formik>
    );
  } else {
    return null;
  }
};
