import { Input as AntdInput } from "antd";
import * as types from "./Input.types";
import * as CONSTANTS from "./Input.constants";

export default function Input({
  placeholder,
  onChange,
  onFocus,
  onBlur,
  disabled,
  autoFocus,
  type = CONSTANTS.INPUT_TYPES.TEXT,
  value,
  count,
  style,
  readOnly,
}: types.InputProps) {
  if (type === CONSTANTS.INPUT_TYPES.TEXTAREA) {
    return (
      <AntdInput.TextArea
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        value={value}
        count={count}
        autoSize={{ minRows: 3, maxRows: 6 }}
        onFocus={onFocus}
        onBlur={onBlur}
        style={style}
        readOnly={readOnly}
      />
    );
  }
  if (type === CONSTANTS.INPUT_TYPES.PASSWORD) {
    return (
      <AntdInput.Password
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={onChange}
        size="large"
        disabled={disabled}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        style={style}
        readOnly={readOnly}
      />
    );
  }
  return (
    <AntdInput
      placeholder={placeholder}
      onChange={onChange}
      size="large"
      disabled={disabled}
      autoFocus={autoFocus}
      value={value}
      type={type}
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
      readOnly={readOnly}
    />
  );
}
