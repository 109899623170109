import { COLORS } from "../../Constants/styles.constants";
import Button from "../Button";
import Text from "../Text";
import * as types from "./Fallback.types";

function Fallback({
  imgSrc,
  heading,
  subText,
  ctaTitle,
  ctaOnClick,
  loading,
  occupyAvailable,
}: types.FallbackProps) {
  return (
    <div
      style={
        occupyAvailable
          ? { width: "100%", height: "75vh" }
          : {
              position: "fixed",
              inset: 0,
              zIndex: 1000,
              backgroundColor: COLORS.WHITE,
            }
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "16px",
          height: "100%",
        }}
      >
        <img src={imgSrc} style={{ width: "160px" }} alt="fallback" />
        <Text fontSize="X_LARGE" fontWeight="SEMI_BOLD">
          {heading}
        </Text>
        {subText ? <p>{subText}</p> : null}
        <Button
          text={ctaTitle}
          size="large"
          onClick={ctaOnClick}
          loading={loading}
          type="primary"
        />
      </div>
    </div>
  );
}

export default Fallback;
