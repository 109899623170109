import { Avatar as AntdAvatar } from "antd";
import * as types from "./Avatar.types";

export default function Avatar({
  src,
  alt,
  size = 64,
  shape = "circle",
  style,
  onClick,
}: types.AvatarProps) {
  return (
    <AntdAvatar
      onClick={onClick}
      src={src}
      size={size}
      alt={alt}
      shape={shape}
      onError={() => true}
      style={{ fontSize: size / 2, ...style }}
    >
      {alt[0]}
    </AntdAvatar>
  );
}
