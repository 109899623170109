// wrapper for fetch api

import { AUTH } from "../../Constants";
import { ServerResponseData, ValueOf } from "../Types";
import * as CONSTANTS from "./http.constants";
import * as types from "./http.types";

export async function request<ResponseData extends unknown>(
  method: ValueOf<typeof CONSTANTS.HTTP_METHODS>,
  url: string,
  data: any,
  { authentification = true, headers }: types.HTTP_OPTIONS = {}
) {
  const authToken = authentification
    ? localStorage.getItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY)
    : null;
  const response = await fetch(`/api${url}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...(authToken && {
        Authorization: `Bearer ${authToken}`,
      }),
      ...headers,
    },
    ...(method !== CONSTANTS.HTTP_METHODS.GET && {
      body: JSON.stringify(data),
    }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "An error occurred");
  }

  return response.json() as Promise<ResponseData>;
}

export function mapServerErrorsToLocal<
  TResult,
  FormErrors = unknown,
  FormFields = unknown
>(error: ServerResponseData<TResult, FormFields>): FormErrors {
  const localErrors: any = {};

  // Check if error object is present and has error field
  if (error && error.error) {
    const errorDetail = error.error;

    if (errorDetail.error_messages && errorDetail.error_messages.length > 0) {
      // If there are field-specific errors, map them
      errorDetail.error_messages.forEach((err) => {
        localErrors[err.field] = err.validation;
      });
    }

    // Only add the general error if no field-specific errors exist
    if (Object.keys(localErrors).length === 0 && errorDetail.description) {
      localErrors.general = errorDetail.description;
    }
  }

  return localErrors as FormErrors;
}
export function resetServerErrors<FormErrors extends Record<string, any>>(
  errors: FormErrors,
  fieldsToReset?: string[]
): FormErrors {
  const updatedErrors: FormErrors = { ...errors };
  if (!fieldsToReset) {
    Object.keys(errors).forEach((field) => {
      (updatedErrors as any)[field] = "";
    });
  } else {
    fieldsToReset.forEach((field) => {
      (updatedErrors as any)[field] = "";
    });
  }
  return updatedErrors;
}

export const DEV_SPECIFIED_ERR = "Dev: Specific Field Error";

export const ERROR_MESSAGES = {
  GENERIC_SERVER_ERROR_MSG: "Oops! Something went wrong. Please try again.",
};
