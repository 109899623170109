import React from "react";
import { Menu as AntdMenu } from "antd";
import * as types from "./Menu.types";
import styled from "styled-components";
import { COLORS } from "../../Constants/styles.constants";

function Menu({ items, onClick }: types.MenuProps) {
  const [selectedKey, setSelectedKey] = React.useState<string | null>(null);

  const handleClick = (info: { key: string }) => {
    setSelectedKey(info.key);
    onClick?.(info);
  };

  return (
    <StyledMenu
      items={items.map((item) => ({
        ...item,
        icon: (
          <div style={{ height: "18px", width: "18px" }}>
            <img
              src={
                selectedKey === item.key && item.activeIcon
                  ? item.activeIcon
                  : item.inactiveIcon || item.icon || ""
              }
              alt={item.label}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ),
      }))}
      inlineCollapsed
      mode="inline"
      onClick={handleClick}
    />
  );
}

export default Menu;

const StyledMenu = styled(AntdMenu)`
  .ant-menu-item {
    transition: all 0.3s ease;
    text-align: center;
    color: white !important;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 !important;
  }

  .ant-menu-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-item {
    margin-bottom: 6px !important;
  }

  .ant-menu-item.ant-menu-item-selected {
    background-color: ${COLORS.PRIMARY} !important;
  }

  .ant-menu-title-content {
    display: none !important;
  }
`;
