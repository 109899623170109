import {
  Avatar,
  Button,
  DonutChart,
  Modal,
  Select,
  Tag,
  Text,
} from "../../Components";
import {
  ApplicationBasicInfo,
  ApplicationBasicSecond,
  ApplicationItemWrapper,
  ApplicationTitle,
  ApplicationTitleWrapper,
  LabelInfoWrapper,
  IndustryWrapper,
  ApplicationsListWrapper,
  ApplicantProfileContent,
  ProfileInfoWrapper,
  BasicInfo,
  NameInfo,
  AppliedTimeWrapper,
  RightSection,
  SecondaryInfoWrapper,
  BasicTitleInfo,
  SecondaryInfoList,
  InsightsWrapper,
  InsightsTitle,
  InsightsContentWrapper,
  InsightInfoList,
  InsightDetailWrapper,
  ChartsWrapper,
  InsightChartWrapper,
  DonutChartWrapper,
  ChartLegendWrapper,
  ChartLegend,
  LegendTitle,
  ActionButtonsWrapper,
  RejectReasonWrapper,
  ApplicationSecondaryInfo,
  ApprovedApplicationsListWrapper,
} from "./BrandApplications.styles";
import * as services from "./BrandApplications.services";
import * as types from "./BrandApplications.types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { ValueOf } from "../../Utilities/Types";
import * as CONSTANTS from "./BrandApplications.constants";
import { dateTime } from "../../Utilities/DateTime";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { ICONS } from "../../Assets/Icons/Icons";
import { COLORS } from "../../Constants/styles.constants";
import { formatNumberToString } from "../../Utilities/Number/number.utlities";
import { Modal as AntdModal } from "antd";
import CustomTooltip from "../../Components/Tooltip/Tooltip";

function LabelInfo({ title, value, numberIsString }: types.LabelInfoProps) {
  return (
    <div>
      <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
        {numberIsString ? formatNumberToString(Number(value)) : value}
      </Text>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        {title}
      </Text>
    </div>
  );
}

function ApplicationItem({
  application,
  path,
  onSelect,
  selectedApplication,
  setSelectedApplicationRef,
}: types.ApplicationItemProps) {
  const { partner, insights } = application;
  const itemRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (selectedApplication === application.id && setSelectedApplicationRef) {
      setSelectedApplicationRef(itemRef);
    }
  }, [selectedApplication, application.id, setSelectedApplicationRef]);
  return (
    <Link to={path} onClick={() => onSelect(application.id)} ref={itemRef}>
      <ApplicationItemWrapper
        $selected={selectedApplication === application.id}
      >
        <ApplicationTitleWrapper>
          <Avatar
            src={partner?.profile_picture}
            alt={partner?.name}
            size={40}
          />
          <ApplicationBasicInfo>
            <ApplicationTitle fontWeight="SEMI_BOLD" fontSize="SMALL">
              {partner?.name || partner?.username}
            </ApplicationTitle>
            {partner?.name ? (
              <ApplicationBasicSecond>
                <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                  {partner.username}
                </Text>
              </ApplicationBasicSecond>
            ) : null}
          </ApplicationBasicInfo>
        </ApplicationTitleWrapper>
        <ApplicationSecondaryInfo>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <div style={{ height: "16px", width: "16px" }}>
              <img src={ICONS.gender} alt="gender" />
            </div>
            <Text
              fontSize="X_SMALL"
              fontWeight="SEMI_BOLD"
              color="SECONDARY_TEXT"
            >
              {partner?.gender || "N/A"}
            </Text>
          </div>

          {application?.tag ? (
            <Tag color="GREEN">{application?.tag}</Tag>
          ) : null}
        </ApplicationSecondaryInfo>
        <LabelInfoWrapper>
          {insights.map((insight, index) => (
            <LabelInfo
              key={index}
              title={insight?.label}
              value={insight?.value}
              numberIsString={insight?.type === "number"}
            />
          ))}
        </LabelInfoWrapper>

        {partner?.industries && partner.industries.length > 0 ? (
          <IndustryWrapper>
            {partner.industries.slice(0, 2).map((industry, index) => (
              <Tag key={index} color="GREY">
                {industry}
              </Tag>
            ))}
            {partner?.industries?.length > 2 ? (
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: COLORS.GREY500,
                }}
              >
                +2
              </p>
            ) : null}
          </IndustryWrapper>
        ) : null}
      </ApplicationItemWrapper>
    </Link>
  );
}

export function ApplicationsList({ data }: types.ApplicationsListProps) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [selectedApplication, setSelectedApplication] = useState<number>(
    data.applications[0].id
  );

  const listRef = useRef<HTMLUListElement>(null);
  const selectedRef = useRef<HTMLAnchorElement | null>(null);

  function getUpdatedUrl(applicationId: number) {
    const newParams = new URLSearchParams(params);
    newParams.set("application_id", applicationId.toString());
    return `${location.pathname}?${newParams.toString()}`;
  }

  useEffect(() => {
    if (selectedRef.current && listRef.current) {
      const listHeight = listRef.current.clientHeight;
      const itemHeight = selectedRef.current.clientHeight;
      const itemOffsetTop = selectedRef.current.offsetTop;
      const scrollTopPosition = itemOffsetTop - (listHeight - itemHeight) / 2.7;

      listRef.current.scrollTo({
        top: scrollTopPosition,
        behavior: "smooth",
      });
    }
  }, [selectedApplication]);

  return (
    <ApplicationsListWrapper ref={listRef}>
      {data?.applications.map((application) => (
        <ApplicationItem
          key={application.id}
          application={application}
          path={getUpdatedUrl(application.id)}
          onSelect={(id) => setSelectedApplication(id)}
          selectedApplication={selectedApplication}
          setSelectedApplicationRef={(ref) => {
            if (ref) selectedRef.current = ref.current;
          }}
        />
      ))}
    </ApplicationsListWrapper>
  );
}

export function ApprovedApplications({
  data,
  openModal,
  rejected,
}: types.ApprovedApplicationsProps) {
  return (
    <ApprovedApplicationsListWrapper>
      {data?.applications.map((application) => (
        <ApprovedApplicationItem
          key={application.id}
          application={application}
          openModal={openModal}
          rejected={rejected}
        />
      ))}
    </ApprovedApplicationsListWrapper>
  );
}

function ApprovedApplicationItem({
  application,
  openModal,
  rejected,
}: types.ApprovedApplicationItemProps) {
  const { partner, insights } = application;
  return (
    <div
      style={{
        padding: "12px",
        backgroundColor: COLORS.WHITE,
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "12px",
          borderBottom: `1px solid ${COLORS.GREY200}`,
        }}
      >
        <div style={{ display: "flex", gap: "12px" }}>
          <Avatar
            shape="square"
            src={partner?.profile_picture}
            alt={partner?.name}
            size={124}
          />
          <div>
            <div style={{}}>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "20px",
                }}
              >
                {partner?.name}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "16px",
                  color: COLORS.GREY500,
                }}
              >
                {partner.username}
              </p>
            </div>
            <LabelInfoWrapper>
              {insights.map((insight, index) => (
                <LabelInfo
                  key={index}
                  title={insight?.label}
                  value={insight?.value}
                  numberIsString={insight?.type === "number"}
                />
              ))}
            </LabelInfoWrapper>
            <ApplicationSecondaryInfo>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <div style={{ height: "16px", width: "16px" }}>
                  <img src={ICONS.gender} alt="gender" />
                </div>
                <Text
                  fontSize="X_SMALL"
                  fontWeight="SEMI_BOLD"
                  color="SECONDARY_TEXT"
                >
                  {partner?.gender}
                </Text>
              </div>

              {application?.tag ? (
                <Tag color="GREEN">{application?.tag}</Tag>
              ) : null}
            </ApplicationSecondaryInfo>
          </div>
        </div>
        <div
          onClick={() => openModal(application.id)}
          style={{
            height: "40px",
            width: "40px",
            backgroundColor: COLORS.GREY50,
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <div style={{ width: "16px", height: "16px" }}>
            <img src={ICONS.arrowsExpand} alt="arrow-expand" />
          </div>
        </div>
      </div>

      {rejected ? (
        <div>
          {application.rejection_reasons &&
          application.rejection_reasons.length > 0 ? (
            <div
              style={{
                padding: "8px",
                marginTop: "12px",
                backgroundColor: COLORS.GREY50,
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img src={ICONS.StatusWrong} alt="status-wrong" />
              <div>
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  {application.rejection_reasons[0].title}
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: COLORS.GREY500,
                  }}
                >
                  {application.rejection_reasons[0].description}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      ) : partner?.industries && partner.industries.length > 0 ? (
        <>
          <SecondaryInfoList style={{ margin: "12px 0" }}>
            {partner?.locations && partner?.locations.length > 0 ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <img src={ICONS.locationPin} alt="location-pin" />
                <SecondaryInfo
                  content={partner.locations.join(", ")}
                  textStyle={{ fontSize: "12px" }}
                />
              </div>
            ) : null}
            {partner?.languages && partner?.languages.length > 0 ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <img src={ICONS.microphone} alt="microphone" />
                <SecondaryInfo content={partner.languages.join(", ")} />{" "}
              </div>
            ) : null}
          </SecondaryInfoList>
          <IndustryWrapper>
            {partner.industries.map((industry, index) => (
              <Tag key={index} color="GREY">
                {industry}
              </Tag>
            ))}
          </IndustryWrapper>
        </>
      ) : null}
    </div>
  );
}

function SecondaryInfo({ content, textStyle }: types.SecondaryInfoProps) {
  return (
    <SecondaryInfoWrapper>
      <Text
        style={{
          color: COLORS.GREY500,
          fontSize: "14px",
          fontWeight: "500",
          ...textStyle,
        }}
      >
        {content}
      </Text>
    </SecondaryInfoWrapper>
  );
}

function ProfileInfo({
  partner,
  application,
  description,
}: types.ProfileInfoProps) {
  return (
    <ProfileInfoWrapper>
      <Avatar
        src={partner?.profile_picture}
        alt={partner?.name}
        size={150}
        shape="square"
      />
      <RightSection>
        <BasicInfo>
          <BasicTitleInfo>
            <NameInfo>
              <Text
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "28px",
                }}
              >
                {partner?.name || partner?.username}
              </Text>
              {application?.tag ? (
                <Tag color="GREEN">{application.tag}</Tag>
              ) : null}
            </NameInfo>
            <AppliedTimeWrapper>
              <Text
                style={{
                  color: COLORS.CONTENT_TERTIARY,
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Applied on
              </Text>
              {application.applied_on ? (
                <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                  {dateTime.formatDayMonthYear(application?.applied_on)}
                </Text>
              ) : null}
            </AppliedTimeWrapper>
          </BasicTitleInfo>
          <SecondaryInfoList>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img src={ICONS.locationPin} alt="location-pin" />
              {partner?.locations && partner?.locations.length > 0 ? (
                <SecondaryInfo content={partner.locations.join(", ")} />
              ) : (
                <SecondaryInfo content={"N/A"} />
              )}
            </div>

            <div
              style={{
                width: "1px",
                backgroundColor: COLORS.GREY200,
                height: "10px",
              }}
            ></div>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img src={ICONS.gender} alt="gender" />
              {partner?.gender ? (
                <SecondaryInfo content={partner?.gender} />
              ) : (
                <SecondaryInfo content={"N/A"} />
              )}
            </div>

            <div
              style={{
                width: "1px",
                backgroundColor: COLORS.GREY200,
                height: "10px",
              }}
            ></div>

            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img src={ICONS.microphone} alt="microphone" />
              {partner?.languages && partner?.languages?.length > 0 ? (
                <SecondaryInfo content={partner.languages.join(", ")} />
              ) : (
                <SecondaryInfo content={"N/A"} />
              )}{" "}
            </div>
          </SecondaryInfoList>
        </BasicInfo>
        <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
          {description}
        </Text>
        {partner?.industries && partner.industries.length > 0 ? (
          <IndustryWrapper>
            {partner.industries.map((industry, index) => (
              <Tag key={index} color="GREY">
                {industry}
              </Tag>
            ))}
          </IndustryWrapper>
        ) : null}
      </RightSection>
    </ProfileInfoWrapper>
  );
}

// function InsightDelta({ delta }: types.InsightDeltaProps) {
//   return delta > 0 ? (
//     <Tag color="GREEN" shape="circle">
//       ↑{delta}%
//     </Tag>
//   ) : (
//     <Tag color="RED" shape="circle">
//       ↓{delta}%
//     </Tag>
//   );
// }

const getIcon = (title: string) => {
  switch (title) {
    case "likes":
      return ICONS.heart;
    case "comments":
      return ICONS.comment;
    case "shares":
      return ICONS.share;
    case "saves":
      return ICONS.bookmark;
    case "reach":
      return ICONS.bookmark;
    case "engagement":
      return ICONS.bookmark;
    default:
      return "";
  }
};
const getLabel = (label: string) => {
  switch (label) {
    case "Reach":
      return "Reached";
    case "Engagement":
      return "Engaged";
    default:
      return label;
  }
};

function InsightInfo({ delta, title, value, label }: types.InsightInfoProps) {
  if (title === "followers") {
    return null;
  }

  return (
    // <InsightInfoWrapper>
    //   <Text
    //     style={{
    //       fontSize: "16px",
    //       fontWeight: "600",
    //       lineHeight: "24px",
    //       color: COLORS.GREY500,
    //     }}
    //   >
    //     {label}
    //   </Text>
    //   <InsightInfoContent>
    //     <Text fontSize="X_LARGE" fontWeight="SEMI_BOLD">
    //       {number.formatNumberWithCommas(Number(value))}
    //     </Text>
    //     {delta ? <InsightDelta delta={delta} /> : null}
    //   </InsightInfoContent>
    // </InsightInfoWrapper>
    <div
      style={{
        minWidth: "140px",
        padding: "12px",
        boxSizing: "border-box",
        backgroundColor: COLORS.SECONDARY_BACKGROUND_COLOR,
        border: `1px solid ${COLORS.GREY100}`,
        borderRadius: "8px",
        marginBottom: "12px",
      }}
    >
      {title === "engagement" || title === "reach" ? (
        <p
          style={{
            fontSize: "16px",
            fontWeight: "600",
            color: COLORS.GREY500,
          }}
        >
          Accounts
        </p>
      ) : (
        <div style={{ width: "24px", height: "24px" }}>
          <img
            src={getIcon(title)}
            style={{ width: "100%", height: "auto" }}
            alt="icon"
          />
        </div>
      )}

      <p
        style={{
          fontSize: "16px",
          fontWeight: "600",
          color: COLORS.GREY500,
          marginBottom: "4px",
        }}
      >
        {getLabel(label)}
      </p>
      <p
        style={{
          fontSize: "28px",
          fontWeight: "700",
          lineHeight: "36px",
        }}
      >
        {formatNumberToString(Number(value))}
      </p>
    </div>
  );
}

function BarChart({ distribution }: types.InsightChartProps) {
  const [tooltip, setTooltip] = useState<{
    name: string;
    value: number;
    count: number;
  } | null>(null);
  const [tooltipPos, setTooltipPos] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  let chartData: any = distribution.matches.map((match, index) => ({
    name: match.key,
    value: match.percentage,
    count: match.value,
    color: COLORS.PRIMARY,
  }));

  if (distribution?.other) {
    chartData.push({
      name: distribution?.other && distribution.other.key,
      value: distribution?.other && distribution.other.percentage,
      count: distribution?.other && distribution.other.value,
      color: CONSTANTS.OTHERS_COLOR,
    });
  }

  const handleMouseEnter = (
    e: React.MouseEvent<HTMLDivElement>,
    data: { name: string; value: number; count: number }
  ) => {
    const rect = e.currentTarget.getBoundingClientRect();
    console.log(rect.top, rect.width);
    setTooltipPos({
      top: rect.top - 90,
      left: 60,
    });
    setTooltip(data);
  };

  const handleMouseLeave = () => {
    setTooltip(null);
  };

  if (chartData.length > 0) {
    return (
      <InsightChartWrapper style={{ backgroundColor: COLORS.WHITE }}>
        <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
          {distribution.label}
        </Text>
        <Text
          fontSize="X_SMALL"
          color="SECONDARY_TEXT"
          style={{ marginBottom: "12px" }}
        >
          Targeted {distribution.key} :{" "}
          {distribution.other === null
            ? "All"
            : distribution?.matches
                .map((curr) => {
                  if (curr.key === "Others") {
                    return "";
                  } else {
                    return curr.key;
                  }
                })
                .join(", ")}
        </Text>
        <div>
          {chartData.map((curr: any) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 8, backgroundColor: COLORS.GREY100 }}>
                <div
                  style={{
                    width: `${curr.value}%`,
                    backgroundColor: curr.color,
                    height: "24px",
                    // borderRadius: "8px",
                    // borderTopLeftRadius: "4px",
                    // borderBottomLeftRadius: "4px",
                  }}
                  onMouseEnter={(e) => handleMouseEnter(e, curr)}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
              <div style={{ flex: 2 }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <LegendTitle fontSize="X_SMALL" color="SECONDARY_TEXT">
                    {curr?.name}:
                  </LegendTitle>
                  <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                    {curr?.value}%
                  </Text>
                </div>
              </div>
            </div>
          ))}
        </div>
        {tooltip && (
          <CustomTooltip
            active={!!tooltip}
            payload={tooltip}
            position={tooltipPos}
          />
        )}
      </InsightChartWrapper>
    );
  } else {
    return null;
  }
}

function InsightChart({ distribution, centerText }: types.InsightChartProps) {
  const chartData = distribution.matches.map((match, index) => ({
    name: match.key,
    value: match.percentage,
    count: match.value,
    color: CONSTANTS.MATCH_COLORS[index] || CONSTANTS.MATCH_COLORS[0],
  }));
  if (distribution?.other) {
    chartData.push({
      name: distribution?.other && distribution.other.key,
      value: distribution?.other && distribution.other.percentage,
      color: CONSTANTS.OTHERS_COLOR,
      count: distribution?.other && distribution.other.value,
    });
  }

  return (
    <InsightChartWrapper>
      <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
        {distribution.label}
      </Text>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        Targeted {distribution.key} :{" "}
        {distribution.other === null
          ? "All"
          : distribution?.matches
              .map((curr) => {
                if (curr.key === "Others") {
                  return "";
                } else {
                  return curr.key;
                }
              })
              .join(", ")}
      </Text>
      <DonutChartWrapper>
        <DonutChart
          chartCenterText={centerText ? centerText : ""}
          data={chartData}
        />
        <ChartLegendWrapper>
          {distribution.matches.map((match, index) => (
            <ChartLegend
              key={index}
              $color={
                CONSTANTS.MATCH_COLORS[index] || CONSTANTS.MATCH_COLORS[0]
              }
            >
              <LegendTitle style={{}} fontSize="X_SMALL" color="SECONDARY_TEXT">
                {match?.key}
              </LegendTitle>
              <Text style={{}} fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                {match?.percentage}%
              </Text>
            </ChartLegend>
          ))}
          {distribution.other ? (
            <ChartLegend
              key={distribution?.matches?.length}
              $color={CONSTANTS.OTHERS_COLOR}
            >
              <LegendTitle fontSize="X_SMALL" color="SECONDARY_TEXT">
                {distribution?.other?.key}
              </LegendTitle>
              <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                {distribution?.other?.percentage}%
              </Text>
            </ChartLegend>
          ) : null}
        </ChartLegendWrapper>
      </DonutChartWrapper>
    </InsightChartWrapper>
  );
}

function Insights({ insights }: types.InsightProps) {
  const insightWithDistribution = insights
    ? insights.find((insight) => insight.distributions.length > 0)
        ?.distributions
    : [];

  if (insightWithDistribution && insightWithDistribution.length > 0) {
    return (
      <InsightsWrapper>
        <InsightsTitle fontWeight="SEMI_BOLD" color="SECONDARY_TEXT">
          INSIGHTS
        </InsightsTitle>
        <InsightsContentWrapper>
          <InsightInfoList>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <Text style={{}} fontWeight="SEMI_BOLD">
                Content Engagement Highlights
              </Text>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Last 28 days
                </Text>
              </div>
            </div>
            <div className="insight-info">
              {insights.map((insight) => {
                return (
                  <InsightInfo
                    label={insight?.label}
                    key={insight?.parameter}
                    title={insight?.parameter}
                    value={insight?.count?.toString()}
                    delta={insight?.delta}
                  />
                );
              })}
            </div>
          </InsightInfoList>
          <InsightDetailWrapper>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text fontWeight="SEMI_BOLD">
                Target Audience & Followers Match
              </Text>

              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <Text
                  style={{
                    color: COLORS.CONTENT_TERTIARY,
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Followers:
                </Text>

                <Text
                  fontSize="X_SMALL"
                  fontWeight="SEMI_BOLD"
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {formatNumberToString(
                    insights.filter((curr) => curr.parameter === "followers")[0]
                      .count
                  )}
                </Text>
              </div>
            </div>

            <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
              Understand the overlap between the influencer's followers and your
              campaign's target audience across key demographics.
            </Text>
            {insightWithDistribution.length > 0 ? (
              <ChartsWrapper>
                {insightWithDistribution.map((distribution, index) => {
                  if (
                    distribution.key === "City" ||
                    distribution.key === "Gender-Age"
                  ) {
                    return <></>;
                  }
                  if (!distribution) {
                    return <></>;
                  }
                  return (
                    <InsightChart
                      key={index}
                      distribution={distribution}
                      centerText={formatNumberToString(
                        insights.filter(
                          (curr) => curr.parameter === "followers"
                        )[0].count
                      )}
                    />
                  );
                })}
              </ChartsWrapper>
            ) : null}
          </InsightDetailWrapper>
        </InsightsContentWrapper>
        <div>
          {insightWithDistribution.length > 0 ? (
            <ChartsWrapper>
              {insightWithDistribution.map((distribution, index) => {
                if (!distribution) {
                  return <></>;
                }
                if (distribution.key === "Gender-Age") {
                  return <BarChart key={index} distribution={distribution} />;
                }
                return <></>;
              })}
            </ChartsWrapper>
          ) : null}
        </div>
        <div>
          {insightWithDistribution.length > 0 ? (
            <ChartsWrapper>
              {insightWithDistribution.map((distribution, index) => {
                if (!distribution) {
                  return <></>;
                }
                if (distribution.key === "City") {
                  return <BarChart key={index} distribution={distribution} />;
                }
                return <></>;
              })}
            </ChartsWrapper>
          ) : null}
        </div>
      </InsightsWrapper>
    );
  } else return <></>;
}

export function ActionButtons({ data, showSave }: types.ActionButtonsProps) {
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  const queryClient = useQueryClient();
  const updateQueryParams = useUpdateQueryParams();
  const [confirmActionModal, setConfirmActionModal] = useState<ValueOf<
    typeof CONSTANTS.ACTION_MODAL_TYPES
  > | null>(null);

  const [rejectReason, setRejectReason] = useState<types.RejectReason | null>(
    null
  );

  const { mutateAsync: updateApplicationStatus, isPending } = useMutation({
    mutationFn: services.updateApplicationStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaignApplications"] });
      updateQueryParams({ application_id: "" });
      setConfirmActionModal(null);
    },
  });

  async function handleApplicationStatusChange(
    status: ValueOf<typeof CONSTANTS.APPLICATION_STATUS>
  ) {
    await updateApplicationStatus({ applicationId, status });
  }

  function handleRejectReason(value: unknown) {
    if (typeof value !== "string") {
      return;
    }
    const selectedReason = CONSTANTS.REJECT_REASONS.find(
      (reason) => reason.value === value
    );
    if (!selectedReason) {
      return;
    }
    setRejectReason({
      title: selectedReason.value,
      description: selectedReason.label,
    });
  }

  const isRejectModal =
    confirmActionModal === CONSTANTS.ACTION_MODAL_TYPES.REJECT;

  async function handleConfirmAction() {
    if (!isRejectModal) {
      await handleApplicationStatusChange(
        CONSTANTS.APPLICATION_STATUS.ACCEPTED
      );
      return;
    }
    if (!rejectReason) {
      return;
    }
    await updateApplicationStatus({
      applicationId,
      status: CONSTANTS.APPLICATION_STATUS.REJECTED,
      rejectReason,
    });
    setConfirmActionModal(null);
  }

  const applications = useMemo(() => data?.applications || [], [data]);

  useEffect(() => {
    if (applicationId) {
      return;
    }
    if (!applications.length) {
      return;
    }
    updateQueryParams({
      application_id: applications[0].id.toString(),
    });
  }, [applicationId, applications, updateQueryParams]);

  return (
    <ActionButtonsWrapper>
      {showSave ? (
        <Button
          text="Save"
          size="large"
          onClick={() =>
            handleApplicationStatusChange(CONSTANTS.APPLICATION_STATUS.SAVED)
          }
          style={{ height: "40px" }}
          loading={isPending}
        />
      ) : null}
      <Button
        text="Reject"
        size="large"
        onClick={() =>
          setConfirmActionModal(CONSTANTS.ACTION_MODAL_TYPES.REJECT)
        }
        danger
        loading={isPending}
        style={{ height: "40px" }}
      />
      <Button
        text="Accept"
        size="large"
        type="primary"
        onClick={() =>
          setConfirmActionModal(CONSTANTS.ACTION_MODAL_TYPES.APPROVE)
        }
        loading={isPending}
        style={{ height: "40px" }}
      />
      <Modal
        width={"494px"}
        centered
        open={!!confirmActionModal}
        title={isRejectModal ? "Reject Application?" : "Accept Application?"}
        onCancel={() => {
          setConfirmActionModal(null);
        }}
        onOkay={handleConfirmAction}
        destroyOnClose
        okayText={isRejectModal ? "Reject" : "Accept"}
        okayButtonProps={{ danger: isRejectModal }}
      >
        {isRejectModal ? (
          <>
            <Text
              color="SECONDARY_TEXT"
              style={{ fontSize: "14px", lineHeight: "22px" }}
            >
              Are you sure you want to reject this application? Once rejected
              the action can not be undone, you can save applications to
              shortlist and decide later
            </Text>
            <RejectReasonWrapper>
              <Text fontWeight="SEMI_BOLD" fontSize="SMALL">
                Reason for rejection
              </Text>
              <Select
                options={CONSTANTS.REJECT_REASONS}
                onChange={handleRejectReason}
                placeholder="Select a reason"
                optionRenderer={({ label, value }) => (
                  <div>
                    <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
                      {value}
                    </Text>
                    <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                      {label}
                    </Text>
                  </div>
                )}
              />
            </RejectReasonWrapper>
          </>
        ) : (
          <Text
            color="SECONDARY_TEXT"
            fontSize="X_SMALL"
            style={{ marginBottom: "32px" }}
          >
            Are you sure you want to accept this application? Once accepted,
            this action cannot be reversed.
          </Text>
        )}
      </Modal>
    </ActionButtonsWrapper>
  );
}

function ApplicantProfile({ applicationId }: types.ApplicantProfileProps) {
  const { data } = useQuery({
    queryKey: ["applicantProfile", applicationId],
    queryFn: () => services.getApplicationData(applicationId),
  });
  if (!data) {
    return null;
  }

  return (
    <ApplicantProfileContent>
      <ProfileInfo
        partner={data.result.partner}
        application={data.result.application}
        description={
          data.result?.socials
            ? data.result?.socials[0].value.info?.biography
            : ""
        }
      />
      {data.result?.socials ? (
        <Insights insights={data.result.socials[0].value?.insights} />
      ) : null}
    </ApplicantProfileContent>
  );
}

export function ApplicantProfileContext({
  application_id_prop,
  showModal,
  onClose,
  modal,
}: {
  application_id_prop?: number | null;
  showModal?: boolean;
  onClose?: () => void;
  modal?: boolean;
}) {
  const [searchParams] = useSearchParams();

  const applicationId =
    application_id_prop || Number(searchParams.get("application_id") || 0);

  if (modal) {
    if (application_id_prop && showModal) {
      return (
        <AntdModal
          width={"80%"}
          open={showModal}
          footer={null}
          centered
          onCancel={onClose}
        >
          <div>
            <h2
              style={{
                fontSize: "20px",
                lineHeight: "32px",
                fontWeight: "600",
                marginBottom: "20px",
              }}
            >
              Account insights
            </h2>
            <ApplicantProfile applicationId={applicationId} />
          </div>
        </AntdModal>
      );
    } else {
      return <></>;
    }
  } else {
    if (!applicationId) {
      return null;
    }
    return <ApplicantProfile applicationId={applicationId} />;
  }
}
