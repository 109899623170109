import { COLORS } from "../../Constants/styles.constants";
import * as styles from "../AboutBusiness/AboutBusiness.styles";
import { Button, Input } from "../../Components";
import { useEffect, useRef, useState } from "react";
import { Formik, FormikProps, FormikHelpers } from "formik";
import {
  BusinessUser,
  BusinessUserResponse,
  FormErrors,
  FormValues,
} from "./ContactDetails.types";
import * as Yup from "yup";
import { ServerResponseData } from "../../Utilities/Types";
import { http } from "../../Utilities";
import {
  DEV_SPECIFIED_ERR,
  ERROR_MESSAGES,
  mapServerErrorsToLocal,
  resetServerErrors,
} from "../../Utilities/Http/http.utilities";
import { useMutation } from "@tanstack/react-query";
import { ErrorMessage } from "../../Utilities/Styles/CommonStyledComponents";
import { useNavigate } from "react-router-dom";

const ContactDetails = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
    >
      <div style={{ width: "370px", marginBottom: "140px" }}>
        <h3
          style={{
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "30px",
            textAlign: "center",
            marginBottom: "4px",
          }}
        >
          Your contact details
        </h3>
        <p
          style={{
            textAlign: "center",
            paddingBottom: "28px",
            marginBottom: "28px",
            borderBottom: `1px solid ${COLORS.GREY200}`,
            color: COLORS.GREY500,
          }}
        >
          Help us with your contact details to stay connected
        </p>
        <ContactDetailsForm />
      </div>
    </div>
  );
};

export default ContactDetails;

const ContactDetailsForm = () => {
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const navigation = useNavigate();
  const [data, setData] = useState<null | BusinessUser>(null);

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(
        /^[A-Za-z. ]+$/,
        "Name can only contain alphabets, spaces, and dots"
      )
      .min(3, "Name must be at least 3 characters long")
      .max(50, "Name cannot exceed 50 characters")
      .required("Name is required"),
    email: Yup.string().email("Enter a valid email"),
    phone: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Enter a valid phone number")
      .optional(),
    role: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]+$/,
        "Designation can only contain alphanumeric characters and spaces"
      )
      .min(3, "Designation must be at least 3 characters long")
      .max(50, "Designation cannot exceed 50 characters")
      .optional(),
  });

  const initialValues: FormValues = {
    name: data?.name || "",
    email: data?.email || "",
    phone: data?.phone?.slice(3, 13) || "",
    role: data?.role || "",
  };

  const initialErrors: FormErrors = {
    name: "",
    email: "",
    phone: "",
    role: "",
    general: "",
  };

  const [serverErrors, setServerErrors] = useState<FormErrors>(initialErrors);

  async function updateOnboardingStageAndNavigate() {
    const endpoint = "/brand";
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      await http.put<any>(
        endpoint,
        {
          updates: {
            onboarding_stage: "Completed",
          },
        },
        options
      );
      navigation("/brand/home");
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  const handleUpdatePartner = async (
    values: FormValues
  ): Promise<ServerResponseData<any, FormValues>> => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== "")
    );

    const endpoint = "/business-user";
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      // Assuming the HTTP client returns data in the expected format
      const response = await http.put<ServerResponseData<any, FormValues>>(
        endpoint,
        { updates: filteredValues },
        options
      );
      return response;
    } catch (error) {
      console.error("Error updating partner details:", error);
      throw error; // Rethrow to trigger the `onError` handler
    }
  };

  const onUpdateSuccess = (res: ServerResponseData<any, FormValues>) => {
    if (res?.error?.status_code) {
      const errors = mapServerErrorsToLocal<any, FormErrors, FormValues>(res);
      setServerErrors(errors);
      throw new Error(DEV_SPECIFIED_ERR);
    }
    if (formikRef.current && formikRef.current.values) {
      updateOnboardingStageAndNavigate();
    }
  };

  const onUpdateError = ({
    data: res,
  }: {
    data: ServerResponseData<any, FormValues>;
  }) => {
    if (
      Object.values(serverErrors).some((error) => error) &&
      res?.message !== DEV_SPECIFIED_ERR
    ) {
      setServerErrors((prev) => ({
        ...prev,
        general: ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG,
      }));
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleUpdatePartner,
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  });

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    try {
      // Call the mutation function
      await mutateAsync(values);
      formikHelpers.setSubmitting(false);
    } catch (error) {
      console.error("Error during form submission:", error);
      formikHelpers.setSubmitting(false); // Stop the form submission state
    }
  };

  async function fetchBusinessData() {
    const endpoint = "/business-user";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<BusinessUserResponse>(
        endpoint,
        params,
        options
      );
      setData(response.result.business_users[0]);
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  useEffect(() => {
    fetchBusinessData();
  }, []);

  if (data) {
    return (
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          handleBlur,
          touched,
          isValid,
        }) => {
          return (
            <div>
              <styles.FormLabel>Name*</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter your name"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["name"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("name", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("name")(e);
                  }}
                  value={values.name}
                />
                {(touched.name && errors.name) || serverErrors.name ? (
                  <ErrorMessage>
                    {errors.name || serverErrors.name}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Email</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  readOnly
                  placeholder="Enter your email"
                  style={{
                    width: "100%",
                    height: "36px",
                    backgroundColor: COLORS.GREY100,
                    cursor: "not-allowed",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["email"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("email", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("email")(e);
                  }}
                  value={values.email}
                />
                {(touched.email && errors.email) || serverErrors.email ? (
                  <ErrorMessage>
                    {errors.email || serverErrors.email}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Phone</styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <p style={{ fontWeight: "600", color: COLORS.GREY500 }}>
                    +91
                  </p>
                  <Input
                    placeholder="Enter your phone number"
                    style={{
                      width: "100%",
                      height: "36px",
                    }}
                    onChange={(e) => {
                      const newErrs = resetServerErrors<FormErrors>(
                        serverErrors,
                        ["phone"]
                      );
                      setServerErrors(newErrs);
                      const numericValue = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10); // Limit to numeric and 10 characters
                      setFieldValue("phone", numericValue);
                    }}
                    onBlur={(e) => {
                      handleBlur("phone")(e);
                    }}
                    value={values.phone}
                  />
                </div>

                {(touched.phone && errors.phone) || serverErrors.phone ? (
                  <ErrorMessage>
                    {errors.phone || serverErrors.phone}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Designation</styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <Input
                  placeholder="Enter your designation"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["role"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("role", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("role")(e);
                  }}
                  value={values.role}
                />
                {(touched.role && errors.role) || serverErrors.role ? (
                  <ErrorMessage>
                    {errors.role || serverErrors.role}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.Footer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    text="Go back"
                    onClick={() => {
                      navigation(-1);
                    }}
                  />
                  <Button
                    style={{
                      minWidth: "180px",
                    }}
                    text="Complete Onboarding"
                    type="primary"
                    onClick={handleSubmit}
                    disabled={!isValid || !values.name}
                    loading={isPending}
                  />
                  {serverErrors.general && (
                    <ErrorMessage>{serverErrors.general}</ErrorMessage>
                  )}
                </div>
              </styles.Footer>
            </div>
          );
        }}
      </Formik>
    );
  } else {
    return null;
  }
};
