import { useQuery } from "@tanstack/react-query";
import {
  ActionButtons,
  ApplicantProfileContext,
  ApplicationsList,
  ApprovedApplications,
} from "./BrandApplications.HelperComponents";
import {
  ApplicantProfileWrapper,
  ApplicationTabs,
  FullPageContent,
  PageContent,
  PageTitle,
} from "./BrandApplications.styles";
import * as utilities from "./BrandApplications.utilities";
import * as services from "./BrandApplications.services";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as CONSTANTS from "./BrandApplications.constants";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { ICONS } from "../../Assets/Icons/Icons";
import { ClipLoader } from "react-spinners";
import { COLORS } from "../../Constants/styles.constants";
import Fallback from "../../Components/Fallback";
import { useState } from "react";
import uploadError from "../../Assets/Images/upload_error.png";

function BrandApplications() {
  const { campaignId } = useParams();
  const updateQueryParams = useUpdateQueryParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openApplicationId, setOpenApplicationId] = useState<null | number>(
    null
  );
  const [error5xx, setError5xx] = useState(false);

  const activeTab =
    searchParams.get("tab") || CONSTANTS.APPLICATION_STATUS.APPLIED;

  const { data, isLoading } = useQuery({
    queryKey: ["campaignApplications", campaignId, activeTab],
    queryFn: async () => {
      try {
        const response = await services.getCampaignApplications(
          campaignId ? campaignId : "",
          activeTab
        );

        if (response.error && response?.error?.status_code >= 500) {
          setError5xx(true);
          throw new Error("Server error");
        }

        // Transform the response even if applications is null
        return utilities.transformApplicationList(response);
      } catch (error: any) {
        throw error;
      }
    },
  });

  const handleOpenModal = (application_id: number) => {
    setOpenModal(true);
    setOpenApplicationId(application_id);
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ClipLoader color={COLORS.PRIMARY} size={"48px"} />
      </div>
    );
  }

  if (error5xx) {
    return (
      <Fallback
        imgSrc={uploadError}
        ctaOnClick={async () => window.location.reload()}
        ctaTitle="Try again"
        loading={false}
        heading="Something is off from our side"
        subText="We're sorry for the inconvenience, please try again"
      />
    );
  }

  return (
    <main style={{ height: "100vh", overflow: "hidden" }}>
      <div
        style={{ display: "flex", alignItems: "center", paddingLeft: "16px" }}
      >
        <img
          alt="arrow-left"
          src={ICONS.arrowLeft}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={() => navigate("/brand/campaigns")}
        />
        <div>
          <PageTitle fontSize="X_LARGE" fontWeight="SEMI_BOLD">
            <p>Applications</p>
          </PageTitle>
          <div
            style={{
              fontSize: "14px",
              color: COLORS.GREY500,

              lineHeight: "20p",
            }}
          >
            <span style={{ fontWeight: "400" }}>Title:</span>{" "}
            <span style={{ color: "#000" }}>{data?.campaign.title}</span>
            {data?.campaign?.goal ? (
              <span style={{ fontWeight: "400" }}>
                &nbsp;| Goal:{" "}
                <span style={{ color: "#000" }}>{data?.campaign.goal}</span>
              </span>
            ) : null}
            {data?.campaign?.target_audience?.gender ? (
              <span style={{ fontWeight: "400" }}>
                &nbsp;| Gender:{" "}
                <span style={{ color: "#000" }}>
                  {data?.campaign.target_audience?.gender}
                </span>
              </span>
            ) : null}
            {data?.campaign?.target_audience?.age_ranges &&
            data?.campaign?.target_audience?.age_ranges?.length > 0 ? (
              <span style={{ fontWeight: "400" }}>
                &nbsp;| Age Groups:{" "}
                <span style={{ color: "#000" }}>
                  {data?.campaign.target_audience?.age_ranges?.join(", ")}
                </span>
              </span>
            ) : null}
            {data?.campaign?.target_audience?.locations &&
            data?.campaign?.target_audience?.locations?.length > 0 ? (
              <span style={{ fontWeight: "400" }}>
                &nbsp;| Locations:{" "}
                <span style={{ color: "#000" }}>
                  {data?.campaign.target_audience?.locations
                    .map((curr) => curr.city)
                    ?.join(", ")}
                </span>
              </span>
            ) : null}
            {/* {data?.campaign?.target_audience ? (
              <span style={{ fontWeight: "400" }}>
                &nbsp; | Target Audience:{" "}
                <span style={{ fontWeight: "500", color: "#000" }}>
                  {data?.campaign.target_audience &&
                    data.campaign.target_audience?.gender}
                  ,&nbsp;
                  {data.campaign.target_audience &&
                  data.campaign.target_audience?.age_ranges &&
                  data.campaign.target_audience?.age_ranges.length > 0
                    ? data.campaign.target_audience?.age_ranges?.join(", ")
                    : ""}
                  ,&nbsp;
                  {data.campaign.target_audience &&
                  data.campaign.target_audience?.locations &&
                  data.campaign.target_audience?.locations.length > 0
                    ? data.campaign.target_audience?.locations
                        .map((curr) => curr.city)
                        ?.join(", ")
                    : ""}
                </span>
              </span>
            ) : null} */}
          </div>
        </div>
      </div>
      {campaignId ? (
        <ApplicationTabs
          onChange={(activeKey) => updateQueryParams({ tab: activeKey })}
          items={[
            {
              key: CONSTANTS.APPLICATION_STATUS.APPLIED,
              label: "Applied",
              children: !data?.applications ? (
                <Fallback
                  occupyAvailable
                  imgSrc={uploadError}
                  ctaOnClick={async () => navigate("/brand/campaigns")}
                  ctaTitle="Go back"
                  loading={false}
                  heading={data?.message || ""}
                />
              ) : (
                <PageContent>
                  <ApplicationsList
                    campaignId={campaignId}
                    status={CONSTANTS.APPLICATION_STATUS.APPLIED}
                    data={data}
                  />
                  <div style={{ position: "relative" }}>
                    <ApplicantProfileWrapper>
                      <ApplicantProfileContext />
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          left: 0,
                          marginRight: "16px",
                          marginBottom: "16px",
                          backgroundColor: COLORS.WHITE,
                          height: "70px",
                        }}
                      >
                        <ActionButtons data={data} showSave={false} />
                      </div>
                    </ApplicantProfileWrapper>
                  </div>
                </PageContent>
              ),
            },
            {
              key: CONSTANTS.APPLICATION_STATUS.ACCEPTED,
              label: "Accepted",
              children: !data?.applications ? (
                <Fallback
                  occupyAvailable
                  imgSrc={uploadError}
                  ctaOnClick={async () => navigate("/brand/campaigns")}
                  ctaTitle="Go back"
                  loading={false}
                  heading={data?.message || ""}
                />
              ) : (
                <FullPageContent>
                  <ApprovedApplications
                    data={data}
                    openModal={handleOpenModal}
                  />
                  <ApplicantProfileContext
                    application_id_prop={openApplicationId}
                    showModal={openModal}
                    onClose={() => {
                      setOpenModal(false);
                      setOpenApplicationId(null);
                    }}
                    modal
                  />
                </FullPageContent>
              ),
            },
            {
              key: CONSTANTS.APPLICATION_STATUS.REJECTED,
              label: "Rejected",
              children: !data?.applications ? (
                <Fallback
                  occupyAvailable
                  imgSrc={uploadError}
                  ctaOnClick={async () => navigate("/brand/campaigns")}
                  ctaTitle="Go back"
                  loading={false}
                  heading={data?.message || ""}
                />
              ) : (
                <FullPageContent>
                  <ApprovedApplications
                    data={data}
                    openModal={handleOpenModal}
                    rejected
                  />
                  <ApplicantProfileContext
                    application_id_prop={openApplicationId}
                    showModal={openModal}
                    onClose={() => {
                      setOpenModal(false);
                      setOpenApplicationId(null);
                    }}
                    modal
                  />
                </FullPageContent>
              ),
            },
          ]}
          defaultActiveKey={activeTab || CONSTANTS.APPLICATION_STATUS.APPLIED}
        />
      ) : null}
    </main>
  );
}

export default BrandApplications;
