import {
  Avatar,
  ButtonLikeLink,
  DonutChart,
  Tag,
  Text,
} from "../../Components";
import {
  AdditionalInfoWrapper,
  BlurredImage,
  CampaignHighlightsCard,
  CampaignHighlightsInfo,
  CampaignStatusCardWrapper,
  ChartLegend,
  ChartLegendWrapper,
  DistributionPieChartWrapper,
  DistributionsChartWrapper,
  DistributionsWrapper,
  DonutChartWrapper,
  Gradient,
  IndustryWrapper,
  InfluencerDetailContent,
  InfluencerDetailTopContent,
  InfluencerDetailWrapper,
  InsightCardContent,
  InsightCardWrapper,
  InsightTopSection,
  LegendTitle,
  LivePostWrapper,
  MainChartLegend,
  MainDonutChartWrapper,
  MetricContent,
  MetricWrapper,
  PostImage,
  PostLinkWrapper,
  SecondaryInfoList,
  SecondaryInfoWrapper,
  TagWrapper,
  TitleWrapper,
  TopPerformingSubmission,
  TopPerformingSubmissionMetric,
  TopPerformingSubmissionMetricWrapper,
  TopPerformingSubmissionsContent,
  TopPerformingSubmissionScrollWrapper,
  TopPerformingSubmissionsWrapper,
  TopSubmissionsImage,
} from "./BrandInsights.styles";
import * as types from "./BrandInsights.types";
import * as CONSTANTS from "./BrandInsights.constants";
import { number } from "../../Utilities";
import { getIcons } from "./BrandInsights.utilities";

export function CampaignStatusCard({
  campaign,
}: types.CampaignStatusCardProps) {
  return (
    <CampaignStatusCardWrapper>
      <div>
        <TagWrapper>
          <Tag color="PRIMARY">{campaign.status}</Tag>
        </TagWrapper>
        <TitleWrapper>
          <Text fontSize="LARGE" fontWeight="SEMI_BOLD">
            {campaign.title}
          </Text>
          <Text color="SECONDARY_TEXT" fontSize="SMALL">
            {campaign.description}
          </Text>
        </TitleWrapper>
      </div>
      <CampaignHighlightsCard>
        {campaign.campaignMeta.map((meta) => (
          <CampaignHighlightsInfo key={meta.label}>
            <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
              {meta.label}
            </Text>
            <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
              {meta.value}
            </Text>
          </CampaignHighlightsInfo>
        ))}
      </CampaignHighlightsCard>
    </CampaignStatusCardWrapper>
  );
}

function DistributionPieChart({
  distribution,
}: types.DistributionPieChartProps) {
  const chartData = distribution.values.map((match, index) => ({
    name: match.key,
    value: match.percentage,
    color: CONSTANTS.MATCH_COLORS[index] || CONSTANTS.MATCH_COLORS[0],
  }));

  return (
    <DistributionPieChartWrapper>
      <Text fontWeight="SEMI_BOLD" fontSize="SMALL">
        {distribution.key}
      </Text>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        Match Count: {distribution.count}
      </Text>
      <DonutChartWrapper>
        <DonutChart
          chartCenterText={distribution.count.toString()}
          data={chartData}
        />
        <ChartLegendWrapper>
          {distribution.values.map((match, index) => (
            <ChartLegend
              key={index}
              $color={
                CONSTANTS.MATCH_COLORS[index] || CONSTANTS.MATCH_COLORS[0]
              }
            >
              <LegendTitle fontSize="SMALL" color="SECONDARY_TEXT">
                {match.key}:
              </LegendTitle>
              <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
                {match.percentage}%
              </Text>
            </ChartLegend>
          ))}
        </ChartLegendWrapper>
      </DonutChartWrapper>
    </DistributionPieChartWrapper>
  );
}

function Distributions({ distributions }: types.DistributionsProps) {
  return (
    <DistributionsWrapper>
      <Text color="SECONDARY_TEXT" fontSize="SMALL" fontWeight="SEMI_BOLD">
        REACH DISTRIBUTION
      </Text>
      <DistributionsChartWrapper>
        {distributions.map((distribution, index) => (
          <DistributionPieChart distribution={distribution} key={index} />
        ))}
      </DistributionsChartWrapper>
    </DistributionsWrapper>
  );
}

export function InsightCard({ insight }: types.InsightCardProps) {
  const chartData = insight.connections.map((match, index) => ({
    name: match.key,
    value: match.percentage,
    color: CONSTANTS.MATCH_COLORS[index] || CONSTANTS.MATCH_COLORS[0],
  }));

  chartData.push({
    name: "Others",
    value:
      100 -
      insight.connections.reduce((acc, match) => acc + match.percentage, 0),
    color: CONSTANTS.NON_MATCH_COLOR,
  });

  return (
    <InsightCardWrapper>
      <Text color="SECONDARY_TEXT" fontSize="SMALL" fontWeight="SEMI_BOLD">
        {insight.label}
      </Text>
      <InsightCardContent>
        <InsightTopSection>
          <MainDonutChartWrapper>
            <MainChartLegend>
              <Text
                color="SECONDARY_TEXT"
                fontSize="SMALL"
                fontWeight="SEMI_BOLD"
              >
                {insight.connections[0].label}
              </Text>
              <Text fontWeight="SEMI_BOLD" fontSize="X_LARGE">
                {number.formatNumberWithCommas(insight.connections[0].count)}
              </Text>
              <Text
                color="SECONDARY_TEXT"
                fontSize="SMALL"
                fontWeight="SEMI_BOLD"
              >
                {insight.connections[0].percentage}%
              </Text>
            </MainChartLegend>
            <DonutChart
              chartCenterText={number.formatNumberWithCommas(insight.count)}
              data={chartData}
            />
            <MainChartLegend>
              <Text
                color="SECONDARY_TEXT"
                fontSize="SMALL"
                fontWeight="SEMI_BOLD"
              >
                {insight.connections[1].label}
              </Text>
              <Text fontWeight="SEMI_BOLD" fontSize="X_LARGE">
                {number.formatNumberWithCommas(insight.connections[1].count)}
              </Text>
              <Text
                color="SECONDARY_TEXT"
                fontSize="SMALL"
                fontWeight="SEMI_BOLD"
              >
                {insight.connections[1].percentage}%
              </Text>
            </MainChartLegend>
          </MainDonutChartWrapper>
          <MetricWrapper>
            {insight.metrics.map((metric, index) => (
              <MetricContent key={index}>
                <Text fontSize="X_LARGE">{getIcons(metric.key)}</Text>
                <Text
                  fontSize="X_SMALL"
                  color="SECONDARY_TEXT"
                  fontWeight="SEMI_BOLD"
                >
                  {metric.label}
                </Text>
                <Text fontWeight="SEMI_BOLD" fontSize="XXX_LARGE">
                  {number.abbreviateNumber(metric.value)}
                </Text>
              </MetricContent>
            ))}
          </MetricWrapper>
        </InsightTopSection>
        {insight.distributions && insight.distributions?.length > 0 ? (
          <Distributions distributions={insight.distributions} />
        ) : null}
      </InsightCardContent>
    </InsightCardWrapper>
  );
}

export const EngagmentMetrics = ({ insight }: { insight: types.Insight }) => {
  return (
    <MetricWrapper>
      {insight.metrics.map((metric, index) => (
        <MetricContent key={index}>
          <Text fontSize="X_LARGE">{getIcons(metric.key)}</Text>
          <Text
            fontSize="X_SMALL"
            color="SECONDARY_TEXT"
            fontWeight="SEMI_BOLD"
          >
            {metric.label}
          </Text>
          <Text fontWeight="SEMI_BOLD" fontSize="XXX_LARGE">
            {number.abbreviateNumber(metric.value)}
          </Text>
        </MetricContent>
      ))}
    </MetricWrapper>
  );
};

export function TopSubmissions({
  topPerformingSubmissions,
}: types.TopSubmissionsProps) {
  return (
    <TopPerformingSubmissionsWrapper>
      <Text color="SECONDARY_TEXT" fontSize="SMALL" fontWeight="SEMI_BOLD">
        TOP PERFORMING SUBMISSIONS
      </Text>
      <TopPerformingSubmissionScrollWrapper>
        <TopPerformingSubmissionsContent>
          {topPerformingSubmissions.map((submission, index) => (
            <TopPerformingSubmission key={index}>
              <div>
                <TopSubmissionsImage
                  src={submission.thumbnail}
                  alt={submission.partner.name}
                />
              </div>
              <div>
                <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                  {submission.partner.name}
                </Text>
                <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
                  {submission.partner.username}
                </Text>
                <TopPerformingSubmissionMetricWrapper>
                  {submission.metrics.map((metric, index) => (
                    <TopPerformingSubmissionMetric key={index}>
                      <Text color="SECONDARY_TEXT">{getIcons(metric.key)}</Text>
                      <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                        {number.abbreviateNumber(metric.value)}
                      </Text>
                    </TopPerformingSubmissionMetric>
                  ))}
                </TopPerformingSubmissionMetricWrapper>
              </div>
            </TopPerformingSubmission>
          ))}
        </TopPerformingSubmissionsContent>
      </TopPerformingSubmissionScrollWrapper>
    </TopPerformingSubmissionsWrapper>
  );
}

export function LivePost({ submission }: types.LivePostProps) {
  return (
    <LivePostWrapper>
      <BlurredImage src={submission.thumbnail} />
      <PostImage src={submission.thumbnail} />
      <PostLinkWrapper>
        <ButtonLikeLink
          to={submission.post_url}
          text="Go to live post on Instagram"
        />
      </PostLinkWrapper>
      <Gradient />
    </LivePostWrapper>
  );
}

function SecondaryInfo({ content }: types.SecondaryInfoProps) {
  return (
    <SecondaryInfoWrapper>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        {content}
      </Text>
    </SecondaryInfoWrapper>
  );
}

export function InfluencerDetail({
  additionalInfo,
  partner,
}: types.InfluencerDetailProps) {
  return (
    <InfluencerDetailWrapper>
      <Text color="SECONDARY_TEXT" fontSize="SMALL" fontWeight="SEMI_BOLD">
        INFLUENCER DETAILS
      </Text>
      <InfluencerDetailContent>
        <Avatar
          src={partner.profile_picture}
          alt={partner.name}
          size={160}
          shape="square"
        />
        <div>
          <InfluencerDetailTopContent>
            <Text fontSize="LARGE" fontWeight="SEMI_BOLD">
              {partner.name}
            </Text>
            <SecondaryInfoList>
              <SecondaryInfo content={partner.locations.join(", ")} />
              <SecondaryInfo content={partner.gender} />
              <SecondaryInfo content={partner.languages.join(", ")} />
            </SecondaryInfoList>
          </InfluencerDetailTopContent>
          <IndustryWrapper>
            {partner.industries.map((industry, index) => (
              <Tag key={index} color="GREY">
                {industry}
              </Tag>
            ))}
          </IndustryWrapper>
        </div>
        <AdditionalInfoWrapper>
          {additionalInfo.map((info) => (
            <div key={info.key}>
              <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                {info.label}
              </Text>
              <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                {info.value}
              </Text>
            </div>
          ))}
        </AdditionalInfoWrapper>
      </InfluencerDetailContent>
    </InfluencerDetailWrapper>
  );
}
