export const COLORS = {
  PRIMARY_TEXT: "#362B48",
  SECONDARY_TEXT: "#5C6674",
  PRIMARY: "#7B6CF0",
  WHITE: "#FFFFFF",
  SECONDARY_BACKGROUND_COLOR: "#F6F7F8",
  PRIMARY_LIGHT_BACKGROUND_COLOR: "#FAFAFF",
  BORDER_COLOR: "#E0E3E5",
  ERROR_COLOR: "#E91B16",
  GREY100: "#ECEEF1",
  CONTENT_TERTIARY: "#AEB6C4",
  POSITIVE_NORMAL: "#35915E",
  //
  ORANGE50: "#FEEFE4",
  ORANGE500: "#C05806",
  RED50: "#FDE6E5",
  RED500: "#B51511",
  GREEN50: "#EAF7F0",
  GREEN500: "#35915E",
  YELLOW50: "#FEF7E3",
  YELLOW500: "#C18C06",
  GREY50: "#F6F7F8",
  GREY200: "#E0E3E5",
  GREY300: "#C8CED7",
  GREY400: "#AEB6C4",
  GREY500: "#5C6674",
  GREY700: "#394455",
  PURPLE50: "#F4F3FF",
  GREY900: "#1A202B",
} as const;

export const BASE_THEME = {
  token: {
    colorPrimary: COLORS.PRIMARY,
  },
};
