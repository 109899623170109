import * as types from "./Tabs.types";
import { StyledTabs } from "./Tabs.styles";

function Tabs({
  defaultActiveKey,
  items,
  onChange,
  style,
  className,
  tabBarStyle,
}: types.TabsProps) {
  return (
    <StyledTabs
      style={style}
      defaultActiveKey={defaultActiveKey}
      items={items}
      onChange={onChange}
      className={className}
      tabBarStyle={tabBarStyle}
    />
  );
}

export default Tabs;
