import { useCallback, useEffect, useState } from "react";
import { Form as AntdForm } from "antd";
import styled from "styled-components";

import {
  Button,
  Form,
  FormItem,
  Text,
  Input,
  Avatar,
  ButtonLikeLink,
} from "../../Components";
import { url } from "../../Utilities/Url";
import { UserInfoWrapper, UserTextInfoWrapper } from "./Login.styles";
import * as types from "./Login.types";
import { validatePassword } from "./Login.utilities";
import { COLORS } from "../../Constants/styles.constants";
import { ICONS } from "../../Assets/Icons/Icons";

export function UserExistsForm({
  isLoading,
  onSubmit,
}: types.UserExistsFormProps) {
  const [form] = AntdForm.useForm();
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = useCallback(async () => {
    try {
      await form.validateFields({ validateOnly: true });
      setIsFormValid(true);
    } catch (error) {
      setIsFormValid(false);
    }
  }, [form]);

  const handleEmailChange = () => {
    validateForm();
  };

  return (
    <Form<types.UserStatusFormData> onSubmit={onSubmit} form={form}>
      <FormItem
        label={<Text>Enter Email</Text>}
        name="email"
        rules={[
          {
            type: "email",
            message: "Please enter a correct email address",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input
          placeholder="eg. xyz@gmail.com"
          autoFocus
          type="email"
          onChange={handleEmailChange}
        />
      </FormItem>
      <FormItem style={{ marginBottom: 0 }}>
        <Button
          text="Continue with Email"
          fullWidth
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          disabled={!isFormValid}
        />
      </FormItem>
    </Form>
  );
}

export function SignInForm({
  isLoading,
  onSubmit,
  userData,
  inputError,
  handleChange,
}: types.SignInFormProps) {
  const [form] = AntdForm.useForm();
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = useCallback(async () => {
    try {
      await form.validateFields({ validateOnly: true });
      setIsFormValid(true);
    } catch (error) {
      setIsFormValid(false);
    }
  }, [form]);

  const handlePasswordChange = () => {
    handleChange();
    validateForm();
  };

  return (
    <div>
      <UserInfoWrapper>
        <Avatar
          src="https://www.w3schools.com/howto/img_avatar.png"
          size={64}
          alt="User Avatar"
        />
        <UserTextInfoWrapper>
          <Text fontWeight="SEMI_BOLD">{userData.email}</Text>
          <Text color="SECONDARY_TEXT">{userData.email}</Text>
        </UserTextInfoWrapper>
      </UserInfoWrapper>
      <Form<types.SignInFormData> onSubmit={onSubmit} form={form}>
        <FormItem hidden name="email" initialValue={userData.email}>
          <input type="hidden" value={userData.email} />
        </FormItem>
        <FormItem
          style={{ marginBottom: "0" }}
          label={<Text>Password</Text>}
          name="password"
          validationStatus={inputError ? "error" : ""}
          help={inputError || ""}
        >
          <Input
            placeholder="Enter your password"
            type="password"
            autoFocus
            onChange={handlePasswordChange}
          />
        </FormItem>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ButtonLikeLink
            style={{ paddingRight: 0 }}
            to={`/forgot-password${url.toUrlSearchString({
              email: userData.email,
            })}`}
            text="Forgot Password?"
            type="LINK"
          />
        </div>
        <FormItem style={{ marginBottom: "0" }}>
          <Button
            text="Proceed to Sign In"
            fullWidth
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            disabled={!isFormValid}
          />
        </FormItem>
      </Form>
    </div>
  );
}

export function SignUpForm({
  isLoading,
  onSubmit,
  userData,
}: types.SignUpFormProps) {
  const [form] = AntdForm.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const validateForm = useCallback(async () => {
    try {
      await form.validateFields({ validateOnly: true });
      setIsFormValid(true);
    } catch (error) {
      setIsFormValid(false);
    }
  }, [form]);

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    validateForm();
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    form.setFieldsValue({ password: value });
    validateForm();
  };

  return (
    <Form<types.SignUpFormData> onSubmit={onSubmit} form={form}>
      <FormItem
        label={<Text>Enter Email</Text>}
        name="email"
        initialValue={userData.email}
        rules={[
          {
            type: "email",
            message: "Please enter a correct email address",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input
          placeholder="eg. xyz@gmail.com"
          onChange={handleFieldChange}
          readOnly
          style={{
            width: "100%",
            height: "36px",
            backgroundColor: COLORS.GREY100,
            cursor: "not-allowed",
          }}
        />
      </FormItem>
      <div style={{ position: "relative" }}>
        <PasswordInstructor password={password} isVisible={isPasswordFocused} />

        <FormItem
          style={{ position: "relative" }}
          label={<Text>Password</Text>}
          name="password"
          rules={[
            { required: true, message: "Please input your password!" },
            // eslint-disable-next-line no-empty-pattern
            ({}) => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }
                const passwordError = validatePassword(value);
                if (!passwordError) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Sorry, your password is not strong")
                );
              },
            }),
          ]}
        >
          <Input
            placeholder="Enter your password"
            type="password"
            onChange={(e) => handlePasswordChange(e.target.value)}
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
          />
        </FormItem>
      </div>

      <FormItem
        label={<Text>Confirm Password</Text>}
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("The passwords do not match!"));
            },
          }),
        ]}
      >
        <Input
          placeholder="Confirm your password"
          type="password"
          onChange={handleFieldChange}
        />
      </FormItem>
      <FormItem>
        <Button
          text="Proceed to Sign Up"
          fullWidth
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          disabled={!isFormValid}
        />
      </FormItem>
    </Form>
  );
}

export function PasswordInstructor({
  password,
  isVisible,
}: types.PasswordInstructorProps) {
  const [validationState, setValidationState] = useState({
    isValidLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });

  useEffect(() => {
    const isValidLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /\d/.test(password);

    setValidationState({
      isValidLength,
      hasUpperCase,
      hasLowerCase,
      hasSpecialChar,
      hasNumber,
    });
  }, [password]);

  if (!isVisible) return null;

  const renderValidationRule = (isValid: boolean, text: string) => (
    <Rule
      style={{ color: isValid ? COLORS.POSITIVE_NORMAL : COLORS.ERROR_COLOR }}
    >
      <img
        src={isValid ? ICONS.StatusCorrect : ICONS.StatusWrong}
        alt={isValid ? "Valid" : "Invalid"}
      />
      <RuleText>{text}</RuleText>
    </Rule>
  );

  return (
    <PasswordInstructorCont>
      <Text fontWeight="SEMI_BOLD" lineHeight="1">
        Your password should have:
      </Text>
      <RulesWrapper>
        {renderValidationRule(validationState.isValidLength, "8 characters")}
        {renderValidationRule(
          validationState.hasUpperCase && validationState.hasLowerCase,
          "Upper & lower case letters"
        )}
        {renderValidationRule(
          validationState.hasSpecialChar,
          "A special character (#$&)"
        )}
        {renderValidationRule(validationState.hasNumber, "A number")}{" "}
      </RulesWrapper>
    </PasswordInstructorCont>
  );
}

const PasswordInstructorCont = styled.div`
  position: absolute;
  background-color: ${COLORS.WHITE};
  left: 0;
  top: 26px;
  transform: translateX(Calc(-100% - 16px));
  box-shadow: 4px 4px 30px 0px #0000001a;
  padding: 24px;
  border-radius: 8px;

  &::after {
    content: "";
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid white;
    right: -10px;

    top: 6px;
  }
`;

const RulesWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Rule = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const RuleText = styled.span`
  font-size: 12px;
  line-height: 16px;
`;
