import { useEffect, useRef, useState } from "react";
import { Button, Input, Tabs } from "../../Components";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { useSearchParams } from "react-router-dom";

import { COLORS } from "../../Constants/styles.constants";
import {
  Brand,
  BrandResponse,
  MetaOnboardingResponse,
  MetaOnboardingResult,
} from "../AboutBusiness/AboutBusiness.types";
import { FormValues, FormErrors, SocialFormValues } from "./BrandProfile.types";
import {
  FormValues as ContactFormValues,
  FormErrors as ContactFormErrors,
  BusinessUserResponse,
} from "../ContactDetails/ContactDetails.types";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { http } from "../../Utilities";
import { ServerResponseData } from "../../Utilities/Types";
import {
  DEV_SPECIFIED_ERR,
  ERROR_MESSAGES,
  mapServerErrorsToLocal,
  resetServerErrors,
} from "../../Utilities/Http/http.utilities";
import { useMutation } from "@tanstack/react-query";
import * as styles from "../AboutBusiness/AboutBusiness.styles";
import { ErrorMessage } from "../../Utilities/Styles/CommonStyledComponents";
import { Select } from "antd";
import { BusinessUser } from "../ContactDetails/ContactDetails.types";
import styled from "styled-components";

const BrandProfile = () => {
  const TABS = {
    ABOUT_BUSINESS: "about-business",
    CONTACT_DETAILS: "contact-details",
    SOCIALS: "socials",
  };
  const updateQueryParams = useUpdateQueryParams();
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get("tab") || TABS.ABOUT_BUSINESS;

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      {/* <div
        style={{
          height: "60px",
          padding: "16px 18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: COLORS.WHITE,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <img
            src={ICONS.arrowLeft}
            alt="Back"
            onClick={() => {
              navigate("/brand/home");
            }}
            style={{ cursor: "pointer" }}
          />
          <PageHeading>Brand</PageHeading>
        </div>
      </div> */}
      <Tabs
        tabBarStyle={{
          padding: "0 16px",
          height: "40px",
        }}
        onChange={(activeKey) => updateQueryParams({ tab: activeKey })}
        items={[
          {
            key: TABS.ABOUT_BUSINESS,
            label: "About business",
            children: <AboutBusinessTab />,
          },
          {
            key: TABS.SOCIALS,
            label: "Socials",
            children: <SocialsTab />,
          },
          {
            key: TABS.CONTACT_DETAILS,
            label: "Contact details",
            children: <ContactDetailsTab />,
          },
        ]}
        defaultActiveKey={activeTab || TABS.ABOUT_BUSINESS}
      />
    </div>
  );
};

export default BrandProfile;

// Shared container styles
const TabContainer = styled.div`
  width: 100%;
  background-color: ${COLORS.GREY50};
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Inner wrapper for the tab content
const TabContentWrapper = styled.div`
  width: 562px;
  padding: 28px;
  height: calc(100vh - 40px);
  overflow: auto;
  background-color: ${COLORS.WHITE};
`;

// Heading styles
const TabHeading = styled.h3`
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  margin-bottom: 4px;
`;

// Subheading (paragraph) styles
const TabSubheading = styled.p`
  text-align: center;
  padding-bottom: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid ${COLORS.GREY200};
  color: ${COLORS.GREY500};
`;

const AboutBusinessTab = () => {
  return (
    <TabContainer>
      <div
        style={{
          position: "relative",
        }}
      >
        <TabContentWrapper>
          <div>
            <TabHeading>About your brand</TabHeading>
            <TabSubheading>
              Information about your brand and business
            </TabSubheading>
          </div>
          <AboutBusinessTabForm />
        </TabContentWrapper>
      </div>
    </TabContainer>
  );
};

const SocialsTab = () => {
  return (
    <TabContainer>
      <div
        style={{
          position: "relative",
        }}
      >
        <TabContentWrapper>
          <div>
            <TabHeading>Your Online Presence</TabHeading>
            <TabSubheading>
              Add links to your social media platforms
            </TabSubheading>
          </div>
          <SocialsTabForm />
        </TabContentWrapper>
      </div>
    </TabContainer>
  );
};

const ContactDetailsTab = () => {
  return (
    <TabContainer>
      <div
        style={{
          position: "relative",
        }}
      >
        <TabContentWrapper>
          <div>
            <TabHeading>Your contact information</TabHeading>
            <TabSubheading>
              We’ll need your details to stay connected Information
            </TabSubheading>
          </div>
          <ContactDetailsTabForm />
        </TabContentWrapper>
      </div>
    </TabContainer>
  );
};

const AboutBusinessTabForm = () => {
  const [options, setOptions] = useState<null | MetaOnboardingResult>(null);
  const [brandData, setBrandData] = useState<null | Brand>(null);

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Business name must be at least 3 characters long")
      .required("Business name is required")
      .max(50, "Business name cannot exceed 50 characters"),
    business_type: Yup.string().optional(),
    company_size: Yup.string().optional(),
    collaboration_experience: Yup.string().optional(),
    website: Yup.string().url().optional(),
    legal_name: Yup.string()
      .min(3, "Legal name must be at least 3 characters long")
      .max(100, "Legal name cannot exceed 100 characters")
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Legal name can only contain alphanumeric characters and spaces"
      )
      .optional(),
    description: Yup.string()
      .min(3, "Description must be at least 3 characters long")
      .max(2000, "Description cannot exceed 2000 characters")
      .optional(),
    tagline: Yup.string()
      .min(3, "Tagline must be at least 3 characters long")
      .max(100, "Tagline cannot exceed 100 characters")
      .optional(),
    logo: Yup.string().url().optional(),
  });

  const initialValues: FormValues = {
    name: brandData?.name || "",
    business_type: brandData?.business_type || undefined,
    company_size: brandData?.company_size || undefined,
    collaboration_experience: brandData?.collaboration_experience || undefined,
    website: brandData?.website || "",
    legal_name: brandData?.legal_name || "",
    description: brandData?.description || "",
    tagline: brandData?.tagline || "",
    logo: brandData?.logo || "",
  };

  const initialErrors: FormErrors = {
    name: "",
    business_type: "",
    company_size: "",
    collaboration_experience: "",
    website: "",
    tagline: "",
    description: "",
    logo: "",
    legal_name: "",
    general: "",
  };

  const [serverErrors, setServerErrors] = useState<FormErrors>(initialErrors);

  async function fetchBrandOnboardingData() {
    const endpoint = "/meta/brand-onboarding";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<MetaOnboardingResponse>(
        endpoint,
        params,
        options
      );
      setOptions(response.result);
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  async function fetchBrandData() {
    const endpoint = "/brand";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<BrandResponse>(endpoint, params, options);
      setBrandData(response.result.brand);
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  const handleUpdatePartner = async (
    values: FormValues
  ): Promise<ServerResponseData<any, FormValues>> => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== "")
    );

    const endpoint = "/brand";
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      // Assuming the HTTP client returns data in the expected format
      const response = await http.put<ServerResponseData<any, FormValues>>(
        endpoint,
        { updates: filteredValues },
        options
      );
      return response;
    } catch (error) {
      console.error("Error updating partner details:", error);
      throw error; // Rethrow to trigger the `onError` handler
    }
  };

  const onUpdateSuccess = (res: ServerResponseData<any, FormValues>) => {
    if (res?.error?.status_code) {
      const errors = mapServerErrorsToLocal<any, FormErrors, FormValues>(res);
      setServerErrors(errors);
      throw new Error(DEV_SPECIFIED_ERR);
    }
    if (formikRef.current && formikRef.current.values) {
      window.location.reload();
    }
  };

  const onUpdateError = ({
    data: res,
  }: {
    data: ServerResponseData<any, FormValues>;
  }) => {
    if (
      Object.values(serverErrors).some((error) => error) &&
      res?.message !== DEV_SPECIFIED_ERR
    ) {
      setServerErrors((prev) => ({
        ...prev,
        general: ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG,
      }));
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleUpdatePartner,
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  });

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    try {
      // Call the mutation function
      await mutateAsync(values);
      formikHelpers.setSubmitting(false);
    } catch (error) {
      console.error("Error during form submission:", error);
      formikHelpers.setSubmitting(false); // Stop the form submission state
    }
  };

  useEffect(() => {
    fetchBrandOnboardingData();
    fetchBrandData();
  }, []);

  if (options && brandData) {
    return (
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          handleBlur,
          touched,
          isValid,
          dirty,
        }) => {
          return (
            <div style={{ paddingBottom: "70px" }}>
              <styles.FormLabel>Brand Name*</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter your Brand name or Business name"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["name"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("name", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("name")(e);
                  }}
                  value={values.name}
                />
                {(touched.name && errors.name) || serverErrors.name ? (
                  <ErrorMessage>
                    {errors.name || serverErrors.name}
                  </ErrorMessage>
                ) : null}
              </div>
              <styles.FormLabel>Legal name</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter legal name of your business"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["legal_name"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("legal_name", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("legal_name")(e);
                  }}
                  value={values.legal_name}
                />
                {(touched.legal_name && errors.legal_name) ||
                serverErrors.legal_name ? (
                  <ErrorMessage>
                    {errors.legal_name || serverErrors.legal_name}
                  </ErrorMessage>
                ) : null}
              </div>
              <styles.FormLabel>Business Description</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  type="textarea"
                  placeholder="Describe what your business does"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["description"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("description", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("description")(e);
                  }}
                  value={values.description}
                />
                {(touched.description && errors.description) ||
                serverErrors.description ? (
                  <ErrorMessage>
                    {errors.description || serverErrors.description}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>What describes you best?</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Select
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select"
                  onChange={(val) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["business_type"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("business_type", val);
                  }}
                  value={values.business_type}
                  options={options.business_types.map((curr) => {
                    return {
                      label: curr,
                      value: curr,
                    };
                  })}
                />
                {(touched.business_type && errors.business_type) ||
                serverErrors.business_type ? (
                  <ErrorMessage>
                    {errors.business_type || serverErrors.business_type}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Your company size</styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <Select
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select"
                  value={values.company_size}
                  onChange={(val) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["company_size"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("company_size", val);
                  }}
                  options={options.company_sizes.map((curr) => {
                    return {
                      label: curr.slice(0, -1) + " Employees" + curr.slice(-1),
                      value: curr,
                    };
                  })}
                />
                {(touched.company_size && errors.company_size) ||
                serverErrors.company_size ? (
                  <ErrorMessage>
                    {errors.company_size || serverErrors.company_size}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>
                Your experience in influencer marketing
              </styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <Select
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  placeholder="Select"
                  value={values.collaboration_experience}
                  onChange={(val) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["collaboration_experience"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("collaboration_experience", val);
                  }}
                  options={options.collaboration_experiences.map((curr) => {
                    return {
                      label: `${curr} Campaigns`,
                      value: curr,
                    };
                  })}
                />
                {(touched.collaboration_experience &&
                  errors.collaboration_experience) ||
                serverErrors.collaboration_experience ? (
                  <ErrorMessage>
                    {errors.collaboration_experience ||
                      serverErrors.collaboration_experience}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Website or Public URL</styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <Input
                  placeholder="Enter your company website or linkedin page url"
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["website"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("website", e.target.value);
                  }}
                  onBlur={handleBlur("website")}
                  value={values.website}
                />
                {(touched.website && errors.website) || serverErrors.website ? (
                  <ErrorMessage>
                    {errors.website || serverErrors.website}
                  </ErrorMessage>
                ) : null}
              </div>
              <styles.FormLabel>Logo</styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <Input
                  placeholder="Enter your logo URL"
                  onChange={(e) => {
                    const newErrs = resetServerErrors<FormErrors>(
                      serverErrors,
                      ["logo"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("logo", e.target.value);
                  }}
                  onBlur={handleBlur("logo")}
                  value={values.logo}
                />
                {(touched.logo && errors.logo) || serverErrors.logo ? (
                  <ErrorMessage>
                    {errors.logo || serverErrors.logo}
                  </ErrorMessage>
                ) : null}
              </div>

              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  padding: "12px",
                  backgroundColor: COLORS.WHITE,
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  minHeight: "70px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  text="Save changes"
                  style={{ height: "40px", minWidth: "180px" }}
                  type="primary"
                  onClick={handleSubmit}
                  disabled={!isValid || !values.name || !dirty}
                  loading={isPending}
                />
                {serverErrors.general && (
                  <ErrorMessage>{serverErrors.general}</ErrorMessage>
                )}
              </div>
            </div>
          );
        }}
      </Formik>
    );
  } else {
    return null;
  }
};

const SocialsTabForm = () => {
  const [brandData, setBrandData] = useState<null | Brand>(null);

  const formikRef = useRef<FormikProps<SocialFormValues>>(null);

  const [serverError, setServerError] = useState("");

  const validationSchema = Yup.object({
    instagram: Yup.string()
      .matches(
        /^https:\/\/www\.instagram\.com\/[a-zA-Z0-9_.]+\/?$/,
        "Enter a valid Instagram URL"
      )
      .optional(),
    linkedin: Yup.string()
      .matches(
        /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
        "Enter a valid LinkedIn URL"
      )
      .optional(),
    twitter: Yup.string()
      .matches(
        /^https:\/\/twitter\.com\/[a-zA-Z0-9_]+\/?$/,
        "Enter a valid Twitter URL"
      )
      .optional(),
    facebook: Yup.string()
      .matches(
        /^https:\/\/www\.facebook\.com\/[a-zA-Z0-9.]+\/?$/,
        "Enter a valid Facebook URL"
      )
      .optional(),
  });

  const initialValues: SocialFormValues = {
    instagram:
      brandData?.links?.filter((curr) => curr.key === "instagram")[0]?.value ||
      "",
    facebook:
      brandData?.links?.filter((curr) => curr.key === "facebook")[0]?.value ||
      "",
    twitter:
      brandData?.links?.filter((curr) => curr.key === "twitter")[0]?.value ||
      "",
    linkedin:
      brandData?.links?.filter((curr) => curr.key === "linkedin")[0]?.value ||
      "",
  };

  async function fetchBrandData() {
    const endpoint = "/brand";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<BrandResponse>(endpoint, params, options);
      setBrandData(response.result.brand);
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  const handleUpdatePartner = async (
    values: SocialFormValues
  ): Promise<ServerResponseData<any, SocialFormValues>> => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== "")
    );

    const mappedLinks = Object.entries(filteredValues).map(([key, value]) => ({
      key,
      value,
    }));

    const endpoint = "/brand";
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    const payload = {
      links: mappedLinks,
    };

    try {
      // Assuming the HTTP client returns data in the expected format
      const response = await http.put<
        ServerResponseData<any, SocialFormValues>
      >(endpoint, { updates: payload }, options);
      return response;
    } catch (error) {
      console.error("Error updating partner details:", error);
      throw error; // Rethrow to trigger the `onError` handler
    }
  };

  const onUpdateSuccess = (res: ServerResponseData<any, SocialFormValues>) => {
    if (res?.error?.status_code) {
      setServerError(ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG);
      throw new Error(DEV_SPECIFIED_ERR);
    }
    if (formikRef.current && formikRef.current.values) {
      window.location.reload();
    }
  };

  const onUpdateError = ({
    data: res,
  }: {
    data: ServerResponseData<any, SocialFormValues>;
  }) => {
    if (res?.message !== DEV_SPECIFIED_ERR) {
      setServerError(ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG);
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleUpdatePartner,
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  });

  const handleSubmit = async (
    values: SocialFormValues,
    formikHelpers: FormikHelpers<SocialFormValues>
  ) => {
    try {
      // Call the mutation function
      await mutateAsync(values);
      formikHelpers.setSubmitting(false);
    } catch (error) {
      console.error("Error during form submission:", error);
      formikHelpers.setSubmitting(false); // Stop the form submission state
    }
  };

  useEffect(() => {
    fetchBrandData();
  }, []);

  if (brandData) {
    return (
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          handleBlur,
          touched,
          isValid,
          dirty,
        }) => {
          return (
            <div style={{ paddingBottom: "70px" }}>
              <styles.FormLabel>
                Link to your Instagram profile
              </styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter your Instagram URL"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    setFieldValue("instagram", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("instagram")(e);
                  }}
                  value={values.instagram}
                />
                {touched.instagram && errors.instagram ? (
                  <ErrorMessage>{errors.instagram}</ErrorMessage>
                ) : null}
              </div>
              <styles.FormLabel>Link to your Linkedin profile</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter your LinkedIn URL"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    setFieldValue("linkedin", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("linkedin")(e);
                  }}
                  value={values.linkedin}
                />
                {touched.linkedin && errors.linkedin ? (
                  <ErrorMessage>{errors.linkedin}</ErrorMessage>
                ) : null}
              </div>
              <styles.FormLabel>Link to your Twitter profile</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter your Twitter URL"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    setFieldValue("twitter", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("twitter")(e);
                  }}
                  value={values.twitter}
                />
                {touched.twitter && errors.twitter ? (
                  <ErrorMessage>{errors.twitter}</ErrorMessage>
                ) : null}
              </div>
              <styles.FormLabel>Link to your Facebook profile</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter your Facebook URL"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    setFieldValue("facebook", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("facebook")(e);
                  }}
                  value={values.facebook}
                />
                {touched.facebook && errors.facebook ? (
                  <ErrorMessage>{errors.facebook}</ErrorMessage>
                ) : null}
              </div>

              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  padding: "12px",
                  backgroundColor: COLORS.WHITE,
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  minHeight: "70px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  text="Save changes"
                  style={{ height: "40px", minWidth: "180px" }}
                  type="primary"
                  onClick={handleSubmit}
                  disabled={!isValid || !dirty}
                  loading={isPending}
                />
                {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
              </div>
            </div>
          );
        }}
      </Formik>
    );
  } else {
    return null;
  }
};

const ContactDetailsTabForm = () => {
  const formikRef = useRef<FormikProps<ContactFormValues>>(null);

  const [data, setData] = useState<null | BusinessUser>(null);

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(
        /^[A-Za-z. ]+$/,
        "Name can only contain alphabets, spaces, and dots"
      )
      .min(3, "Name must be at least 3 characters long")
      .max(50, "Name cannot exceed 50 characters")
      .required("Name is required"),
    email: Yup.string().email("Enter a valid email"),
    phone: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Enter a valid phone number")
      .optional(),
    role: Yup.string()
      .matches(
        /^[A-Za-z0-9 ]+$/,
        "Designation can only contain alphanumeric characters and spaces"
      )
      .min(3, "Designation must be at least 3 characters long")
      .max(50, "Designation cannot exceed 50 characters")
      .optional(),
  });

  const initialValues: ContactFormValues = {
    name: data?.name || "",
    email: data?.email || "",
    phone: data?.phone?.slice(3, 13) || "",
    role: data?.role || "",
  };

  const initialErrors: ContactFormErrors = {
    name: "",
    email: "",
    phone: "",
    role: "",
    general: "",
  };

  const [serverErrors, setServerErrors] =
    useState<ContactFormErrors>(initialErrors);

  const handleUpdatePartner = async (
    values: ContactFormValues
  ): Promise<ServerResponseData<any, ContactFormValues>> => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== "")
    );

    const endpoint = "/business-user";
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      // Assuming the HTTP client returns data in the expected format
      const response = await http.put<
        ServerResponseData<any, ContactFormValues>
      >(endpoint, { updates: filteredValues }, options);
      return response;
    } catch (error) {
      console.error("Error updating partner details:", error);
      throw error; // Rethrow to trigger the `onError` handler
    }
  };

  const onUpdateSuccess = (res: ServerResponseData<any, ContactFormValues>) => {
    if (res?.error?.status_code) {
      const errors = mapServerErrorsToLocal<
        any,
        ContactFormErrors,
        ContactFormValues
      >(res);
      setServerErrors(errors);
      throw new Error(DEV_SPECIFIED_ERR);
    }
    if (formikRef.current && formikRef.current.values) {
      window.location.reload();
    }
  };

  const onUpdateError = ({
    data: res,
  }: {
    data: ServerResponseData<any, FormValues>;
  }) => {
    if (
      Object.values(serverErrors).some((error) => error) &&
      res?.message !== DEV_SPECIFIED_ERR
    ) {
      setServerErrors((prev) => ({
        ...prev,
        general: ERROR_MESSAGES.GENERIC_SERVER_ERROR_MSG,
      }));
    }
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: handleUpdatePartner,
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  });

  const handleSubmit = async (
    values: ContactFormValues,
    formikHelpers: FormikHelpers<ContactFormValues>
  ) => {
    try {
      // Call the mutation function
      await mutateAsync(values);
      formikHelpers.setSubmitting(false);
    } catch (error) {
      console.error("Error during form submission:", error);
      formikHelpers.setSubmitting(false); // Stop the form submission state
    }
  };

  async function fetchBusinessData() {
    const endpoint = "/business-user";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<BusinessUserResponse>(
        endpoint,
        params,
        options
      );
      setData(response.result.business_users[0]);
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  useEffect(() => {
    fetchBusinessData();
  }, []);

  if (data) {
    return (
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          handleBlur,
          touched,
          isValid,
          dirty,
        }) => {
          return (
            <div style={{ paddingBottom: "70px" }}>
              <styles.FormLabel>Name*</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  placeholder="Enter your name"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<ContactFormErrors>(
                      serverErrors,
                      ["name"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("name", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("name")(e);
                  }}
                  value={values.name}
                />
                {(touched.name && errors.name) || serverErrors.name ? (
                  <ErrorMessage>
                    {errors.name || serverErrors.name}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Email</styles.FormLabel>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Input
                  readOnly
                  placeholder="Enter your email"
                  style={{
                    width: "100%",
                    height: "36px",
                    backgroundColor: COLORS.GREY100,
                    cursor: "not-allowed",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<ContactFormErrors>(
                      serverErrors,
                      ["email"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("email", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("email")(e);
                  }}
                  value={values.email}
                />
                {(touched.email && errors.email) || serverErrors.email ? (
                  <ErrorMessage>
                    {errors.email || serverErrors.email}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Phone</styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <p style={{ fontWeight: "600", color: COLORS.GREY500 }}>
                    +91
                  </p>
                  <Input
                    placeholder="Enter your phone number"
                    style={{
                      width: "100%",
                      height: "36px",
                    }}
                    onChange={(e) => {
                      const newErrs = resetServerErrors<ContactFormErrors>(
                        serverErrors,
                        ["phone"]
                      );
                      setServerErrors(newErrs);
                      const numericValue = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10); // Limit to numeric and 10 characters
                      setFieldValue("phone", numericValue);
                    }}
                    onBlur={(e) => {
                      handleBlur("phone")(e);
                    }}
                    value={values.phone}
                  />
                </div>
                {(touched.phone && errors.phone) || serverErrors.phone ? (
                  <ErrorMessage>
                    {errors.phone || serverErrors.phone}
                  </ErrorMessage>
                ) : null}
              </div>

              <styles.FormLabel>Designation</styles.FormLabel>
              <div style={{ marginBottom: "20px" }}>
                <Input
                  placeholder="Enter your designation"
                  style={{
                    width: "100%",
                    height: "36px",
                  }}
                  onChange={(e) => {
                    const newErrs = resetServerErrors<ContactFormErrors>(
                      serverErrors,
                      ["role"]
                    );
                    setServerErrors(newErrs);
                    setFieldValue("role", e.target.value);
                  }}
                  onBlur={(e) => {
                    handleBlur("role")(e);
                  }}
                  value={values.role}
                />
                {(touched.role && errors.role) || serverErrors.role ? (
                  <ErrorMessage>
                    {errors.role || serverErrors.role}
                  </ErrorMessage>
                ) : null}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  padding: "12px",
                  backgroundColor: COLORS.WHITE,
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  minHeight: "70px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  text="Save changes"
                  style={{ height: "40px", minWidth: "180px" }}
                  type="primary"
                  onClick={handleSubmit}
                  disabled={!isValid || !values.name || !dirty}
                  loading={isPending}
                />
                {serverErrors.general && (
                  <ErrorMessage>{serverErrors.general}</ErrorMessage>
                )}
              </div>
            </div>
          );
        }}
      </Formik>
    );
  } else {
    return null;
  }
};
