import StatusCorrect from "./StatusCorrect.svg";
import StatusWrong from "./StatusWrong.svg";
import home from "./home.svg";
import megaphone from "./megaphone.svg";
import settings from "./settings.svg";
import logout from "./logout.svg";
import ThreeDot from "./ThreeDot.svg";
import Google from "./Google.svg";
import image_55 from "./image_55.svg";
import image_56 from "./image_56.svg";
import arrowRight from "./arrow-right.svg";
import arrowLeft from "./arrow-left.svg";
import plus from "./plus.svg";
import microphone from "./microphone-stand.svg";
import gender from "./gender.svg";
import locationPin from "./location-pin.svg";
import arrowsExpand from "./arrows-expand.svg";
import comment from "./comment.svg";
import share from "./share.svg";
import bookmark from "./bookmark.svg";
import heart from "./heart.svg";
import onboardingLine from "./onboarding-line.svg";
import check from "./check.png";
import whiteHome from "./white-home.svg";
import whiteLogout from "./white-logout.svg";
import whiteMegaPhone from "./white-megaphone.svg";
import whiteUser from "./whiteuser-square.svg";
import user from "./user-square.png";

export const ICONS = {
  StatusCorrect,
  StatusWrong,
  home,
  megaphone,
  settings,
  logout,
  ThreeDot,
  Google,
  image_55,
  image_56,
  arrowRight,
  arrowLeft,
  plus,
  microphone,
  gender,
  locationPin,
  arrowsExpand,
  comment,
  share,
  bookmark,
  heart,
  onboardingLine,
  check,
  whiteHome,
  whiteLogout,
  whiteMegaPhone,
  whiteUser,
  user,
};
