import { Outlet, useLocation } from "react-router-dom";
import {
  AuthLayoutWrapper,
  LayoutHeroImage,
  LayoutLeft,
  LayoutRight,
} from "../../Router/Router.styles";
import { Text } from "../../Components";
import Logo from "../../Assets/Images/logo.png";
import { COLORS } from "../../Constants/styles.constants";
import { ICONS } from "../../Assets/Icons/Icons";
import { CHILD_PATHS } from "../../Pages/Brand/Brand.constants";

const Onboarding = () => {
  return (
    <AuthLayoutWrapper>
      <LayoutLeft>
        <LayoutHeroImage src={Logo} alt="Ryme Logo" />
        <Text fontSize="XXX_LARGE" fontWeight="SEMI_BOLD" lineHeight="1">
          Unleash authentic brand storytelling with ryme.ai
        </Text>
      </LayoutLeft>
      <LayoutRight
        style={{
          padding: 0,
          position: "relative",
        }}
      >
        <div style={{ height: "100vh", overflow: "auto" }}>
          <OnboardingTracker />
          <Outlet />
        </div>
      </LayoutRight>
    </AuthLayoutWrapper>
  );
};

export default Onboarding;

const OnboardingTracker = () => {
  const location = useLocation();
  const isContactDetailsStep = location.pathname.includes(
    CHILD_PATHS.contactDetails
  );

  return (
    <div style={{ marginTop: "16px" }}>
      <div style={{ padding: "0 30px" }}>
        <h2
          style={{
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "26px",
            letterSpacing: "-0.04px",
            textAlign: "center",
            marginBottom: "4px",
          }}
        >
          Brand onboarding
        </h2>
        <p
          style={{
            textAlign: "center",
            marginBottom: "12px",
            color: COLORS.GREY500,
          }}
        >
          Complete the below steps to create your Ryme account
        </p>
      </div>

      {/* Tracker */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "1px",
            backgroundColor: COLORS.GREY300,
            flex: 1,
          }}
        />
        <div
          style={{
            border: `2px solid ${COLORS.GREY200}`,
            height: "42px",
            flex: 5,
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "0 32px",
            boxShadow: "4px 4px 30px 0px #0000001A",
          }}
        >
          {/* Step 1: Signup */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
                border: `1px solid ${COLORS.GREY300}`,
                marginRight: "2px",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "10px",
                  lineHeight: "12px",
                }}
              >
                1
              </p>
            </div>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                marginRight: "4px",
              }}
            >
              Signup
            </p>
            <div style={{ height: "24px", width: "24px" }}>
              <img
                src={ICONS.check}
                style={{ width: "100%", height: "auto" }}
                alt="check"
              />
            </div>
          </div>

          <div>
            <img src={ICONS.onboardingLine} alt="ui-line" />
          </div>

          {/* Step 2: Complete Profile */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "20px",
                borderRadius: "50%",
                border: `1px solid ${COLORS.GREY300}`,
                marginRight: "2px",
                backgroundColor: isContactDetailsStep
                  ? "transparent"
                  : COLORS.PRIMARY,
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: isContactDetailsStep ? "#000" : COLORS.WHITE,
                }}
              >
                2
              </p>
            </div>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                marginRight: "4px",
              }}
            >
              About business
            </p>
            {isContactDetailsStep && (
              <div style={{ height: "24px", width: "24px" }}>
                <img
                  src={ICONS.check}
                  style={{ width: "100%", height: "auto" }}
                  alt="check"
                />
              </div>
            )}
          </div>

          <div>
            <img src={ICONS.onboardingLine} alt="ui-line" />
          </div>

          {/* Step 3: Connect Instagram */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "20px",
                width: "20px",
                borderRadius: "50%",
                border: `1px solid ${COLORS.GREY300}`,
                marginRight: "2px",
                backgroundColor: isContactDetailsStep
                  ? COLORS.PRIMARY
                  : "transparent",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: isContactDetailsStep ? COLORS.WHITE : COLORS.GREY400,
                }}
              >
                3
              </p>
            </div>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: isContactDetailsStep ? "#000" : COLORS.GREY400,
                marginRight: "4px",
              }}
            >
              Contact details
            </p>
          </div>
        </div>
        <div
          style={{
            height: "1px",
            backgroundColor: COLORS.GREY300,
            flex: 1,
          }}
        />
      </div>
    </div>
  );
};
