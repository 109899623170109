import styled from "styled-components";
import { COLORS } from "../../Constants/styles.constants";

export const FormLabel = styled.p`
  margin-bottom: 6px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY200};
  padding: 12px 16px;
  display: flex;
  justify-content: flex-end;
`;
