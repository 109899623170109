import { Outlet, useNavigate } from "react-router-dom";
import {
  BrandContent,
  BrandLogo,
  BrandNavigation,
  BrandWrapper,
} from "./Brand.styles";
import { Menu } from "../../Components";
import { AUTH } from "../../Constants";
import { useEffect, useRef, useState } from "react";
import { MENU_ITEMS } from "./Brand.constants";
import LogoImage from "../../Assets/Images/logo.png";
import { ICONS } from "../../Assets/Icons/Icons";

function Brand() {
  const navigate = useNavigate();
  const navRef = useRef<HTMLDivElement>(null);
  const [navWidth, setNavWidth] = useState(0);

  function handleLogout() {
    localStorage.setItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY, "");
    navigate("/login");
  }

  useEffect(() => {
    const token = localStorage.getItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY);
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (navRef.current) {
      setNavWidth(navRef.current.offsetWidth);
    }
  }, []);

  function handleMenuClick(key?: number | bigint | string | undefined) {
    if (key === "logout") {
      handleLogout();
      return;
    }
    const menuItem = MENU_ITEMS.find((item) => item.key === key);
    navigate(menuItem?.path || "/");
  }

  return (
    <BrandWrapper>
      <BrandNavigation ref={navRef}>
        <BrandLogo src={LogoImage} alt="Ryme Logo" />
        <Menu
          items={MENU_ITEMS.map((item) => ({
            label: item.label,
            key: item.key,
            activeIcon: item.activeIcon,
            inactiveIcon: item.inactiveIcon,
          }))}
          onClick={(info) => handleMenuClick(info?.key)}
        />
        <div
          style={{ height: "18px", width: "18px", cursor: "pointer" }}
          onClick={handleLogout}
        >
          <img
            src={ICONS.logout}
            alt={"Logout icon"}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </BrandNavigation>
      <BrandContent navWidth={navWidth}>
        <Outlet />
      </BrandContent>
    </BrandWrapper>
  );
}

export default Brand;
