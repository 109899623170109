import { ICONS } from "../../Assets/Icons/Icons";

export const MENU_ITEMS = [
  {
    label: "Dashboard",
    key: "home",
    activeIcon: ICONS.whiteHome,
    inactiveIcon: ICONS.home,
    path: "/brand/home",
  },
  {
    label: "My Campaigns",
    key: "notifications",
    activeIcon: ICONS.whiteMegaPhone,
    inactiveIcon: ICONS.megaphone,
    path: "/brand/campaigns",
  },
  {
    label: "Profile",
    key: "profile",
    activeIcon: ICONS.whiteUser,
    inactiveIcon: ICONS.user,
    path: "/brand/profile",
  },
];

export const CHILD_PATHS = {
  aboutBusiness: "about-business",
  contactDetails: "contact-details",
};

export const PATHS = {
  aboutBusiness: `/onboarding/${CHILD_PATHS.aboutBusiness}`,
  contactDetails: `/onboarding/${CHILD_PATHS.contactDetails}`,
};
