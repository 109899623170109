import { ButtonLikeLink, Text } from "../../Components";
import {
  VerifyEmailActions,
  VerifyEmailContainer,
  VerifyEmailContent,
  VerifyEmailImage,
} from "./VerifyEmail.styles";
import EmailIllustration from "../../Assets/Images/email_illustrations.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { postExchangeToken } from "./VerifyEmail.services";
import { AUTH } from "../../Constants";
import { http } from "../../Utilities";
import { BrandResponse } from "../AboutBusiness/AboutBusiness.types";

function VerifyEmail() {
  const { token } = useParams();
  const navigate = useNavigate();

  async function checkOnboardingStageAndNavigate() {
    const endpoint = "/brand";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<BrandResponse>(endpoint, params, options);
      if (
        response.result.brand.onboarding_stage.toLowerCase() ===
          "New".toLowerCase() ||
        response.result.brand.onboarding_stage.toLowerCase() ===
          "Ongoing".toLowerCase()
      ) {
        navigate("/onboarding/about-business");
      } else {
        navigate("/brand/home/");
      }
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }
  const { mutateAsync } = useMutation({
    mutationFn: postExchangeToken,
    onSuccess: (res) => {
      if (!res.result.long_lived_token) {
        return;
      }
      localStorage.setItem(
        AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY,
        res.result.long_lived_token
      );
      checkOnboardingStageAndNavigate();
    },
  });

  useEffect(() => {
    if (!token) {
      return;
    }
    mutateAsync(token);
  }, [mutateAsync, token]);

  return (
    <VerifyEmailContainer>
      <VerifyEmailContent>
        <VerifyEmailImage src={EmailIllustration} alt="Email" />
        <Text
          style={{
            fontSize: "24px",
            lineHeight: "30px",
          }}
          lineHeight="1"
          fontSize="LARGE"
          fontWeight="SEMI_BOLD"
          textAlign="CENTER"
        >
          Verify your email address
        </Text>
        <Text
          style={{
            fontSize: "14px",
            lineHeight: "20px",
          }}
          lineHeight="1"
          color="SECONDARY_TEXT"
          textAlign="CENTER"
          fontSize="X_SMALL"
        >
          You’re just one step away from using Ryme, verify your email address
        </Text>
        <VerifyEmailActions>
          <ButtonLikeLink to="/login" text="Go to Login" />
        </VerifyEmailActions>
      </VerifyEmailContent>
    </VerifyEmailContainer>
  );
}

export default VerifyEmail;
